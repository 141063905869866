import { TypeAction, TypeActionEnum, ITypeState } from "./types";

const initialState: ITypeState = {
  types: [],
  selectedType: null,
};

export default function typeReducer(state = initialState, action: TypeAction): ITypeState {
  switch (action.type) {
    case TypeActionEnum.SET_TYPES:
      return { ...state, types: action.payload };
    case TypeActionEnum.SET_SELECTED_TYPE:
      return { ...state, selectedType: action.payload };
    default:
      return state;
  }
}
