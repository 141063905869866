import React, { useEffect, useState } from "react";
import { Table, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";

import { ThemeColors, WindowWidth } from "../consts";
import { useWindowSize } from "../hooks";
import { IOrderItem, IOrderRes } from "../models";
import { ColorSpot, Spinner } from ".";
import { OrderService } from "../api";

const { Text } = Typography;
interface OrderDataType extends IOrderRes {
  key: number;
}
interface OrderItemDataType extends Omit<IOrderItem, "description" | "orderId"> {
  key: number;
}

type Props = {
  id: number;
};

export const Order = ({ id }: Props) => {
  const size = useWindowSize();

  const [loading, setLoading] = useState<boolean>(true);
  const [order, setOrder] = useState<IOrderRes>({} as IOrderRes);

  const getMasterColumns = (): ColumnsType<OrderDataType> => {
    return [
      {
        title: "Order",
        render: (record: OrderDataType) => (
          <>
            <Text strong style={{ color: ThemeColors.FontColor }}>
              recipient name:
            </Text>
            &nbsp;{record.name}
            <br />
            <Text strong style={{ color: ThemeColors.FontColor }}>
              recipient email:
            </Text>
            &nbsp;{record.email}
            <br />
            <Text strong style={{ color: ThemeColors.FontColor }}>
              invoice email:
            </Text>
            &nbsp;{record.paypal}
            <br />
            <Text strong style={{ color: ThemeColors.FontColor }}>
              phone:
            </Text>
            &nbsp;{record.phone}
            <br />
            <Text strong style={{ color: ThemeColors.FontColor }}>
              order date:
            </Text>
            &nbsp;{record.orderDate && moment(record.orderDate).format("DD MMMM YYYY")}
          </>
        ),
        responsive: ["xs"],
      },
      {
        title: "recipient name",
        dataIndex: "name",
        key: "name",
        responsive: ["sm"],
      },
      {
        title: "recipient email",
        dataIndex: "email",
        key: "email",
        responsive: ["sm"],
      },
      {
        title: "invoice email",
        dataIndex: "paypal",
        key: "paypal",
        responsive: ["sm"],
      },
      {
        title: "phone",
        dataIndex: "phone",
        key: "phone",
        responsive: ["sm"],
      },
      {
        title: "order date",
        dataIndex: "orderDate",
        key: "orderDate",
        responsive: ["sm"],
        render: (orderDate: Date) => <span>{orderDate && moment(orderDate).format("DD MMMM YYYY")}</span>,
      },
    ];
  };

  const getDetailsColumns = (): ColumnsType<OrderItemDataType> => {
    return [
      {
        title: "color",
        dataIndex: "colorId",
        key: "colorId",
        render: (colorId: number) => colorId && <ColorSpot colorId={colorId} />,
      },
      {
        title: "name",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "weight",
        dataIndex: "weight",
        key: "weight",
      },
      {
        title: "price",
        dataIndex: "price",
        key: "price",
        render: (price: number) => <span>{`$${price}`}</span>,
      },
    ];
  };

  const getMasterDataSource = (): OrderDataType[] => {
    if (order) {
      const { id, name, email, paypal, address, phone, orderDate, sendDate, shipping, review, rate, order_items } =
        order;
      return [
        { key: id, id, name, email, paypal, address, phone, orderDate, shipping, sendDate, review, rate, order_items },
      ];
    }
    return [];
  };

  const getDetailsDataSource = (record: OrderDataType): OrderItemDataType[] => {
    return record.order_items
      .sort((a, b) => (a.id > b.id ? 1 : a.id < b.id ? -1 : 0))
      .map((item) => {
        const { id, name, price, weight, colorId } = item;
        return { key: id, id, name, price, weight, colorId };
      });
  };

  const detailsExpandable = {
    expandedRowRender: (master: OrderDataType) => (
      <div style={{ backgroundColor: ThemeColors.Main }}>
        <Table
          style={{ width: size.width >= WindowWidth.Sx ? "60%" : "100%" }}
          size="small"
          dataSource={getDetailsDataSource(master)}
          columns={getDetailsColumns()}
          pagination={false}
          summary={(detail) => {
            let totalPrice = master.shipping ?? 0;
            detail.forEach(({ price }) => {
              totalPrice += price ?? 0;
            });
            return (
              <>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}>
                    <Text style={{ color: ThemeColors.FontColor }}>Shipping</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1} />
                  <Table.Summary.Cell index={2} />
                  <Table.Summary.Cell index={3}>
                    <Text>{`$${master.shipping ?? 0}`}</Text>
                  </Table.Summary.Cell>
                </Table.Summary.Row>

                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}>
                    <Text strong style={{ color: ThemeColors.FontColor }}>
                      Total
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1} />
                  <Table.Summary.Cell index={2} />
                  <Table.Summary.Cell index={3}>
                    <Text strong type="danger">
                      {`$${totalPrice}`}
                    </Text>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </>
            );
          }}
        />
      </div>
    ),
  };

  useEffect(() => {
    const fetchOrder = async (id: number) => {
      try {
        if (id) {
          setOrder(await OrderService.fetchOne(id));
        }
      } finally {
        setLoading(false);
      }
    };

    fetchOrder(id);
  }, [id]);

  if (loading) {
    return <Spinner height={100} />;
  }

  return (
    <>
      <Table
        style={{ width: "100%" }}
        size="middle"
        expandable={detailsExpandable}
        dataSource={getMasterDataSource()}
        columns={getMasterColumns()}
        pagination={false}
      />
    </>
  );
};
