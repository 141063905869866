import { createContext, useContext, useRef } from "react";

export interface IContext {
  aboutDollsRef: React.RefObject<HTMLDivElement>;
  aboutMeRef: React.RefObject<HTMLDivElement>;
  contactsRef: React.RefObject<HTMLDivElement>;
}

const AppContext = createContext<IContext>({} as IContext);

export const useAppContext = () => {
  return useContext(AppContext);
};

export const AppContextProvider: React.FC = ({ children }) => {
  const aboutDollsRef = useRef<HTMLDivElement>(null);
  const aboutMeRef = useRef<HTMLDivElement>(null);
  const contactsRef = useRef<HTMLDivElement>(null);
  return (
    <AppContext.Provider value={{ aboutDollsRef, aboutMeRef, contactsRef } as IContext}>{children}</AppContext.Provider>
  );
};
