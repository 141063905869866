import { SubscribersPaginationSettings } from "../../../consts";
import { ISubscribeState, SubscribeAction, SubscribeActionEnum } from "./types";

const initialState: ISubscribeState = {
  subscribers: [],
  page: SubscribersPaginationSettings.Page,
  totalCount: SubscribersPaginationSettings.TotalCount,
  loading: false,
};

export default function subscribeReducer(state = initialState, action: SubscribeAction): ISubscribeState {
  switch (action.type) {
    case SubscribeActionEnum.SET_SUBSCRIBERS:
      return { ...state, subscribers: action.payload };
    case SubscribeActionEnum.SET_SUBSCRIBERS_PAGE:
      return { ...state, page: action.payload };
    case SubscribeActionEnum.SET_SUBSCRIBERS_TOTAL_COUNT:
      return { ...state, totalCount: action.payload };
    case SubscribeActionEnum.SET_SUBSCRIBERS_LOADING:
      return { ...state, loading: action.payload };
    default:
      return state;
  }
}
