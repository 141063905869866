import React, { useEffect, useRef, useState } from "react";
import { Button, Checkbox, Form, Input, message, Modal, Row, Space } from "antd";
import { UploadFile } from "antd/lib/upload/interface";

import { useActions, useFocusRef, useTypedSelector } from "../../hooks";
import { IImagesState } from "../../intfs";
import { getErrorMessage } from "../../utils";
import { IImage, IItem } from "../../models";
import { ImageService, ItemService } from "../../api";
import { PictureWall } from "..";
import {
  DEF_ITEM_DESCRIPTION,
  DEF_ITEM_ID,
  DEF_ITEM_NAME,
  DEF_ITEM_NUMBER,
  DEF_ITEM_PRICE,
  DEF_ITEM_WEIGHT,
  DEF_ITEM_HAS_COLOR,
  ItemsPaginationSettings,
  ThemeColors,
  Urls,
} from "../../consts";
import ReactQuill from "react-quill";

type Props = {
  show: boolean;
  onShow: React.Dispatch<React.SetStateAction<boolean>>;
  editedItem?: IItem;
  setEditedItem?: React.Dispatch<React.SetStateAction<IItem>>;
  admin?: boolean;
};

export const CreateEditItem = ({ show, onShow, editedItem, setEditedItem, admin }: Props) => {
  const [form] = Form.useForm();
  const { fetchItems, fetchAdminItems, fetchTotalCount, setItemsPage } = useActions();

  const {
    type: { selectedType },
    item: { page, totalCount },
  } = useTypedSelector((state) => state);

  const [itemId, setItemId] = useState<number>(DEF_ITEM_ID);
  const [description, setDescription] = useState("");

  const [imagesState, setImagesState] = useState<IImagesState>({
    previewVisible: false,
    previewImage: "",
    previewTitle: "",
    fileList: [],
  });

  const itemNameRef = useFocusRef([show]);
  const imageDeletionRef = useRef<boolean>(false);

  const closeDialog = () => {
    if (setEditedItem) {
      setEditedItem(null!);
    }
    onShow(false);
  };

  const countNextPage = (): number => {
    const pageCount = Math.floor((totalCount + 1) / ItemsPaginationSettings.Limit);
    return (totalCount + 1) % ItemsPaginationSettings.Limit > 0 ? pageCount + 1 : pageCount;
  };

  const submit = async ({ name, price, number, weight, hasColor }: any) => {
    let id;
    let nextPage = page;
    if (!editedItem) {
      nextPage = countNextPage();
      setItemsPage(nextPage);
      id = itemId;
    } else {
      id = editedItem.id;
    }
    try {
      await ItemService.edit({
        id,
        name,
        price,
        number,
        weight,
        description,
        hasColor,
      });
      if (selectedType) {
        admin
          ? fetchAdminItems({ typeId: selectedType.id, page: nextPage })
          : fetchItems({ typeId: selectedType.id, page: nextPage });
        if (!editedItem) {
          fetchTotalCount(selectedType.id);
        }
      }
    } catch (e) {
      message.error(getErrorMessage(e));
    }
    closeDialog();
  };

  const handleCancel = async () => {
    try {
      if (!editedItem) {
        deleteImages();
        await ItemService.delete(itemId);
      } else {
        if (editedItem.images.length !== imagesState.fileList.length || imageDeletionRef.current) {
          if (selectedType) {
            fetchItems({ typeId: selectedType.id, page });
          }
        }
      }
    } catch (e) {
      message.error(getErrorMessage(e));
    }
    closeDialog();
  };

  const deleteImage = async (file: UploadFile<any>) => {
    try {
      let imageFile: string = file.name;
      if (file.xhr) {
        const image = JSON.parse(file.xhr.response) as IImage;
        imageFile = image.file;
      }
      await ImageService.delete(imageFile);
      imageDeletionRef.current = true;
    } catch (e) {
      message.error(getErrorMessage(e));
    }
  };

  const deleteImages = () => {
    imagesState.fileList.forEach((file) => {
      deleteImage(file);
    });
  };

  useEffect(() => {
    if (show) {
      const createItem = async () => {
        try {
          const item = await ItemService.create({
            name: DEF_ITEM_NAME,
            price: DEF_ITEM_PRICE,
            number: DEF_ITEM_NUMBER,
            weight: DEF_ITEM_WEIGHT,
            description: DEF_ITEM_DESCRIPTION,
            hasColor: DEF_ITEM_HAS_COLOR,
            typeId: selectedType?.id,
          });
          setItemId(item.id);
        } catch (e: any) {
          message.error(e.message);
        }
      };
      if (!editedItem) {
        createItem();
      } else {
        const fileList = editedItem.images.map<UploadFile<any>>(
          (image) =>
            ({
              key: image.id,
              uid: String(image.id),
              name: image.file,
              status: "done",
              url: `${process.env.REACT_APP_API_URL || Urls.Api}/${image.file}`,
            } as UploadFile<any>)
        );
        setImagesState((state) => ({ ...state, fileList }));
      }
    }
  }, [show]);

  useEffect(() => {
    if (show) {
      if (!editedItem) {
        form.setFieldsValue({
          name: DEF_ITEM_NAME,
          price: DEF_ITEM_PRICE,
          number: DEF_ITEM_NUMBER,
          weight: DEF_ITEM_WEIGHT,
          description: DEF_ITEM_DESCRIPTION,
          hasColor: DEF_ITEM_HAS_COLOR,
        });
        setImagesState((state) => ({ ...state, fileList: [] }));
      } else {
        form.setFieldsValue({
          name: editedItem.name,
          price: editedItem.price,
          number: editedItem.number,
          weight: editedItem.weight,
          description: editedItem.description,
          hasColor: editedItem.hasColor,
        });
      }
    }
  }, [show]);

  useEffect(() => {
    if (show) {
      imageDeletionRef.current = false;
    }
  }, [show]);

  return (
    <Modal open={show} title={editedItem ? "Edit Item" : "Create Item"} centered footer={false} onCancel={handleCancel}>
      <Form form={form} onFinish={submit} layout="horizontal">
        <Form.Item
          name="name"
          label="Name"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
          rules={[
            {
              required: true,
              message: "Please enter the item name",
            },
          ]}
        >
          <Input style={{ color: ThemeColors.FontColor }} ref={itemNameRef} placeholder="Please enter the item name" />
        </Form.Item>

        <Form.Item
          name="price"
          label="Price"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
          rules={[
            {
              required: true,
              message: "Please enter the item price",
            },
          ]}
        >
          <Input style={{ color: ThemeColors.FontColor }} type="number" placeholder="Please enter the item price" />
        </Form.Item>

        <Form.Item
          name="number"
          label="Quantity"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
          rules={[
            {
              required: true,
              message: "Please enter the item quantity",
            },
          ]}
        >
          <Input style={{ color: ThemeColors.FontColor }} type="number" placeholder="Please enter the item quantity" />
        </Form.Item>

        <Form.Item
          name="weight"
          label="Weight"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
          rules={[
            {
              required: true,
              message: "Please enter the item weight",
            },
          ]}
        >
          <Input style={{ color: ThemeColors.FontColor }} type="number" placeholder="Please enter the item weight" />
        </Form.Item>

        <Form.Item name="description" label="Description" labelCol={{ span: 4 }} wrapperCol={{ span: 20 }}>
          <ReactQuill
            theme="snow"
            placeholder="Please enter the item description"
            value={description}
            onChange={setDescription}
            style={{ height: 150, marginBottom: 30 }}
          />
        </Form.Item>

        <Form.Item name="hasColor" valuePropName="checked" wrapperCol={{ offset: 4, span: 20 }}>
          <Checkbox style={{ backgroundColor: ThemeColors.Main }}>Has color</Checkbox>
        </Form.Item>

        <Form.Item>
          <PictureWall
            itemId={!editedItem ? itemId : editedItem.id}
            imagesState={imagesState}
            setImagesState={setImagesState}
            deleteImage={deleteImage}
          />
        </Form.Item>

        <Row justify="end">
          <Space>
            <Button onClick={handleCancel} style={{ color: ThemeColors.FontColor }}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit" style={{ color: ThemeColors.Main, fontWeight: 500, minWidth: 73 }}>
              OK
            </Button>
          </Space>
        </Row>
      </Form>
    </Modal>
  );
};
