import React from "react";
import { Card, Col, Row, Typography, Avatar, Button, message } from "antd";
import { CloseOutlined } from "@ant-design/icons";

import { useActions, useWindowSize, useTypedSelector } from "../hooks";
import { getErrorMessage } from "../utils";
import { ThemeColors, Urls, WindowWidth } from "../consts";
import { ICartItemRes } from "../models";
import { CartItemService } from "../api";
import { ColorSpot } from ".";

const REACT_APP_API_URL = process.env.REACT_APP_API_URL || Urls.Api;
const { Title } = Typography;

type Props = {
  cartItem: ICartItemRes;
};

export const CartItem = ({ cartItem }: Props) => {
  const size = useWindowSize();
  const { fetchLoadingCart } = useActions();

  const {
    user: {
      user: { id: userId },
    },
    counrty: { selectedCountry },
  } = useTypedSelector((state) => state);

  const {
    colorId,
    item: { images, name, price },
  } = cartItem;

  const handleDelete = async () => {
    try {
      await CartItemService.delete(cartItem.id);
      fetchLoadingCart(userId, selectedCountry);
    } catch (e) {
      message.error(getErrorMessage(e));
    }
  };

  return (
    <Row>
      <Col
        xs={24}
        sm={24}
        md={{ offset: 3, span: 18 }}
        lg={{ offset: 4, span: 16 }}
        xl={{ offset: 5, span: 14 }}
        xxl={{ offset: 6, span: 12 }}
        style={{ padding: 2 }}
      >
        <Card size="small" hoverable style={{ backgroundColor: ThemeColors.White }}>
          <Row>
            <Col span={2}>
              <Avatar
                shape="square"
                size="large"
                src={images.length > 0 ? `${REACT_APP_API_URL}/${images[0].file}` : "#"}
              />
            </Col>
            <Col
              span={size.width < WindowWidth.Sx ? 3 : 2}
              offset={size.width < WindowWidth.Sx ? 1 : 0}
              style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
            >
              {colorId && <ColorSpot colorId={colorId} />}
            </Col>
            <Col
              span={size.width < WindowWidth.Sx ? 9 : 12}
              offset={size.width < WindowWidth.Sx ? 1 : 1}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Title
                level={3}
                className="ff-title m0-title"
                style={{ maxWidth: "100%", color: ThemeColors.FontColor }}
                ellipsis={{ rows: 1 }}
              >
                {name ?? ""}
              </Title>
            </Col>
            <Col
              span={size.width < WindowWidth.Sx ? 4 : 3}
              offset={size.width < WindowWidth.Sx ? 1 : 2}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Title level={3} className="ff-title m0-title" style={{ color: ThemeColors.PriceColor }}>{`$${
                price ?? ""
              }`}</Title>
            </Col>
            <Col
              span={size.width < WindowWidth.Sx ? 3 : 2}
              style={{ display: "flex", alignItems: "center", justifyContent: "end" }}
            >
              <Button
                shape="circle"
                icon={<CloseOutlined />}
                onClick={handleDelete}
                style={{ backgroundColor: ThemeColors.White, color: ThemeColors.FontColor }}
              />
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};
