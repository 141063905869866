import { useNavigate } from "react-router-dom";

import { TypeNames } from "../../../consts";
import { useActions, useTypedSelector } from "../../../hooks";
import { RouteNames } from "../../../routes";

export const BlockPatterns = () => {
  const navigate = useNavigate();

  const { setSelectedType } = useActions();

  const {
    type: { types },
  } = useTypedSelector((state) => state);

  const handleGoToStore = () => {
    const type = types.find((type) => type.name === TypeNames.Patterns);
    if (type) {
      setSelectedType(type);
      navigate(RouteNames.SHOP);
    }
  };

  return (
    <section className="u-clearfix u-section-4" id="carousel_58f4">
      <div className="u-clearfix u-expanded-width u-layout-wrap u-layout-wrap-1">
        <div className="u-layout">
          <div className="u-layout-row">
            <div className="u-container-style u-image u-layout-cell u-left-cell u-size-34 u-image-1">
              <div className="u-container-layout" style={{ cursor: "pointer" }} onClick={handleGoToStore}></div>
            </div>
            <div className="u-container-style u-image u-layout-cell u-right-cell u-size-26 u-image-2">
              <div className="u-container-layout u-valign-middle u-container-layout-2">
                <h1 className="u-text u-text-1 dancing-script">Patterns</h1>
                <div className="u-border-3 u-border-grey-dark-1 u-line u-line-horizontal u-line-1"></div>
                <p className="u-text u-text-2" style={{ fontSize: "1rem" }}>
                  The Patterns contains the instruction with step-by-step photos, as well as the pattern pieces, to sew
                  outfit. For your comfort it is offered in 2 different sizes: Small: 35-39см/14-15" Large:
                  42-45см/16-18"
                </p>
                <p style={{ fontSize: "1rem" }}>Fits for dolls, sell here in this store and similar caliber.</p>
                <a onClick={handleGoToStore} className="u-black u-btn u-button-style u-btn-1">
                  to the store
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
