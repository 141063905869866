import { $authHost, $host } from "./index";
import { IType } from "../models/IType";

export class TypeService {
  static async create(type: Omit<IType, "id">): Promise<IType> {
    const { data } = await $authHost.post<IType>("api/type", type);
    return data;
  }

  static async edit(type: IType): Promise<number[]> {
    const { data } = await $authHost.put<number[]>("api/type", type);
    return data;
  }

  static async delete(id: number): Promise<number> {
    const { data } = await $authHost.delete<number>("api/type", { data: { id } });
    return data;
  }

  static async fetchAll(): Promise<IType[]> {
    const { data } = await $host.get<IType[]>("api/type");
    return data;
  }
}
