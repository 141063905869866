export const BlockWelcome = () => {
  return (
    <section
      className="u-clearfix u-grey-5 u-valign-middle-md u-valign-middle-sm u-valign-middle-xs u-section-1"
      id="sec-c458"
    >
      <div className="u-clearfix u-expanded-width u-layout-wrap u-layout-wrap-1">
        <div className="u-layout">
          <div className="u-layout-row">
            <div className="u-container-style u-image u-layout-cell u-left-cell u-size-32 u-image-1">
              <div className="u-container-layout"></div>
            </div>
            <div className="u-align-left u-container-style u-image u-layout-cell u-right-cell u-size-28 u-image-2">
              <div className="u-container-layout u-valign-top u-container-layout-2">
                <h5 className="u-custom-font u-text u-text-font u-text-1">welcome</h5>
                <h1 className="u-text u-title u-text-2 dancing-script">ElenaPaclinaDolls</h1>
                <p className="u-large-text u-text u-text-variant u-text-3 u-align-right">Since 2015</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
