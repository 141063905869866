import React from "react";
import { Button, Form, Input, message, Row, Typography } from "antd";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

import { useTypedSelector, useActions, useFocusRef } from "../../hooks";
import { getErrorMessage } from "../../utils";
import { Modes, ThemeColors } from "../../consts";
import { GoogleService, SubscribeService } from "../../api";

const { Title, Text } = Typography;

export const SubscribeForm = () => {
  const [form] = Form.useForm();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const { setProcessing } = useActions();
  const {
    util: { processing },
  } = useTypedSelector((state) => state);

  const emailRef = useFocusRef();

  const submit = async ({ email }: any) => {
    setProcessing(true);
    try {
      if (process.env.NODE_ENV === Modes.Development) {
        await SubscribeService.subscribe(email);
        message.success("You successfully subscribed to the ElenaPaclinaDolls' newsletter.");
      } else {
        if (!executeRecaptcha) {
          throw new Error("Execute reCaptcha not available yet!");
        }

        const token = await executeRecaptcha("subscribe");
        const response = await GoogleService.verifyReCaptcha(token);

        if (response.success && response.score > 0.5) {
          await SubscribeService.subscribe(email);
          message.success("You successfully subscribed to the ElenaPaclinaDolls' newsletter.");
        } else {
          throw new Error("No enter! It looks like you are bot.");
        }
      }
    } catch (e) {
      message.error(getErrorMessage(e));
    } finally {
      setProcessing(false);
    }
  };

  return (
    <Form form={form} onFinish={submit} layout="horizontal">
      <Title level={4} className="ff-title" style={{ color: ThemeColors.FontColor }}>
        Newsletter
      </Title>

      <Form.Item
        label="E-mail"
        name="email"
        rules={[
          {
            type: "email",
            message: "E-mail format is not valid",
          },
          {
            required: true,
            message: "Please enter your email",
          },
        ]}
      >
        <Input ref={emailRef} style={{ color: ThemeColors.FontColor }} placeholder="Please enter your email" />
      </Form.Item>

      <Form.Item>
        <Text style={{ color: ThemeColors.FontColor }}>
          Subscribe here, to get updates about new dolls, patterns and special offers!
        </Text>
      </Form.Item>

      <Form.Item>
        <Row justify="end">
          <Button
            type="primary"
            htmlType="submit"
            loading={processing}
            style={{ color: ThemeColors.Main, fontWeight: 500 }}
          >
            Subscribe
          </Button>
        </Row>
      </Form.Item>
    </Form>
  );
};
