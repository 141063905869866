import { $host, $authHost } from "./index";
import { ISubscribe } from "../models/ISubscribe";
import { ISendEmail } from "../types";
import { SubscribersPaginationSettings } from "../consts";

export class SubscribeService {
  static async subscribe(email: string): Promise<ISubscribe> {
    const { data } = await $host.post<ISubscribe>("api/subscribe", { email });
    return { id: data.id, email: data.email };
  }

  static async send(subject: string, text: string): Promise<ISendEmail> {
    const {
      data: { info },
    } = await $authHost.post<ISendEmail>("api/subscribe/send", { subject, text });
    return { info };
  }

  static async fetchAll(
    page: number = SubscribersPaginationSettings.Page,
    limit: number = SubscribersPaginationSettings.Limit
  ): Promise<ISubscribe[]> {
    const { data } = await $authHost.get<ISubscribe[]>("api/subscribe", {
      params: { page, limit },
    });
    return data;
  }

  static async count(): Promise<number> {
    const { data } = await $authHost.get<number>("api/subscribe/count");
    return data;
  }
}
