import React from "react";
import { Col, Row } from "antd";

import { UserInfoForm } from ".";

export const UserInfo = () => {
  return (
    <>
      <Row align="middle" style={{ margin: "1em 0" }}>
        <Col
          xs={{ offset: 2, span: 20 }}
          sm={{ offset: 2, span: 20 }}
          md={{ offset: 5, span: 14 }}
          lg={{ offset: 6, span: 12 }}
          xl={{ offset: 7, span: 10 }}
          xxl={{ offset: 8, span: 8 }}
        >
          <UserInfoForm />
        </Col>
      </Row>
    </>
  );
};
