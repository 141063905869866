import React from "react";
import { Button, Modal, Row } from "antd";
import { Typography } from "antd";
import { ThemeColors } from "../../consts";
import { useNavigate } from "react-router-dom";
import { useActions } from "../../hooks";
import { RouteNames } from "../../routes";

type Props = {
  show: boolean;
  onShow: React.Dispatch<React.SetStateAction<boolean>>;
};

const { Text } = Typography;

export const ConfirmLogout = ({ show, onShow }: Props) => {
  const navigate = useNavigate();
  const { logout, setCartItems, setCartTotal } = useActions();

  const handleLogout = async () => {
    logout();
    setCartItems({ cartId: 0, cartItems: [], error: false, message: "" });
    setCartTotal(0);
    navigate(RouteNames.LANDING);
    closeDialog();
  };

  const closeDialog = () => {
    onShow(false);
  };

  return (
    <Modal
      open={show}
      title="Confirm log out"
      centered
      closable={false}
      footer={[
        <Button
          key="no"
          type="primary"
          onClick={closeDialog}
          style={{ color: ThemeColors.Main, fontWeight: 500, minWidth: 54 }}
        >
          No
        </Button>,
        <Button key="yes" onClick={handleLogout} style={{ color: ThemeColors.FontColor }}>
          Yes
        </Button>,
      ]}
    >
      <Row justify="center">
        <Text style={{ fontSize: "1.1em" }}>Are sure you want to log out?</Text>
      </Row>
    </Modal>
  );
};
