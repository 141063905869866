import React from "react";
import { Button, message, Modal, Row } from "antd";
import { Typography } from "antd";

import { useActions, useTypedSelector } from "../../hooks";
import { deleteImage, getErrorMessage } from "../../utils";
import { LocalStorageNames, ThemeColors } from "../../consts";
import { IType } from "../../models";
import { CartItemService, ImageService, ItemService, TypeService } from "../../api";

type Props = {
  show: boolean;
  onShow: React.Dispatch<React.SetStateAction<boolean>>;
  editedType: IType;
  setEditedType: React.Dispatch<React.SetStateAction<IType>>;
};

const { Text } = Typography;

export const ConfirmDeleteType = ({ show, onShow, editedType, setEditedType }: Props) => {
  const { fetchTypes, fetchItems, fetchCartItems } = useActions();

  const {
    user: {
      user: { id: userId },
    },
    item: { page },
  } = useTypedSelector((state) => state);

  const handleDelete = async () => {
    try {
      const storedSelectedType = localStorage.getItem(LocalStorageNames.Type);
      if (storedSelectedType) {
        const selectedType = JSON.parse(storedSelectedType) as IType;
        if (editedType.id === selectedType.id) {
          localStorage.removeItem(LocalStorageNames.Type);
        }
      }

      await TypeService.delete(editedType.id);
      fetchTypes();

      await ItemService.clear();
      fetchItems({ typeId: editedType.id, page });

      const images = await ImageService.fetchAllToDelete();
      images.forEach((image) => deleteImage(image.file));

      await CartItemService.clear();
      fetchCartItems(userId);
    } catch (e) {
      message.error(getErrorMessage(e));
    }
    closeDialog();
  };

  const closeDialog = () => {
    setEditedType(null!);
    onShow(false);
  };

  return (
    <Modal
      open={show}
      title="Delete Type"
      centered
      closable={false}
      footer={[
        <Button
          key="no"
          type="primary"
          onClick={closeDialog}
          style={{ color: ThemeColors.Main, fontWeight: 500, minWidth: 54 }}
        >
          No
        </Button>,
        <Button key="yes" onClick={handleDelete} style={{ color: ThemeColors.FontColor }}>
          Yes
        </Button>,
      ]}
    >
      <Row justify="center">
        <Text>{`Are sure you want to delete the type "${editedType?.name}"?`}</Text>
      </Row>
      <Row justify="center">
        <Text style={{ color: "red" }}>
          It will remove all the items belong to this type and all such items from a cart.
        </Text>
      </Row>
    </Modal>
  );
};
