import React, { useEffect, useMemo } from "react";
import { Row, Tabs, Typography } from "antd";

import { useActions } from "../hooks";
import { OrdersPaginationSettings, ThemeColors } from "../consts";
import { MainLayout, Orders, UserInfo } from "../components";

const { Title } = Typography;

export const UserProfile = () => {
  const { setOrdersPage } = useActions();

  useEffect(() => {
    setOrdersPage(OrdersPaginationSettings.Page);
  }, []);

  const userProfileTabs = useMemo(
    () => [
      { label: "My Info", key: "1", children: <UserInfo /> },
      { label: "My Orders", key: "2", children: <Orders /> },
    ],
    []
  );

  return (
    <MainLayout>
      <Row justify="center" style={{ marginTop: "6rem" }}>
        <Title level={1} className="ff-title" style={{ color: ThemeColors.FontColor }}>
          My Profile
        </Title>
      </Row>
      <Tabs centered defaultActiveKey="1" items={userProfileTabs} style={{ color: ThemeColors.FontColor }} />
    </MainLayout>
  );
};
