import React from "react";
import { Link } from "react-router-dom";
import { RouteNames } from "../../../../../routes";

export const HeaderTitle = () => {
  return (
    <h3 className="u-align-left-xs u-custom-font u-headline u-text u-text-font u-text-1">
      <Link to={RouteNames.LANDING}>Waldorf Dolls</Link>
    </h3>
  );
};
