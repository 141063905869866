import React, { useEffect } from "react";
import { Col, message, Row, Space, Typography } from "antd";

import { OrderService } from "../api";
import { WindowWidth } from "../consts";

import { getErrorMessage } from "../utils";
import { MainLayout, Order, ReturnToStore } from "../components";
import { useTypedSelector, useActions, useWindowSize, useLocalStorage } from "../hooks";

const { Title } = Typography;

export const PaymentSuccess = () => {
  const size = useWindowSize();
  const { fetchUserInfo, fetchCartItems, fetchCartTotal } = useActions();

  const {
    user: {
      user: { id: userId },
    },
    cart: { items: cartItems, shipping, payment },
  } = useTypedSelector((state) => state);

  const [orderId, setOrderId] = useLocalStorage<number>("orderId", 0);

  useEffect(() => {
    const createOrder = async () => {
      try {
        const order = await OrderService.create(userId, shipping, new Date(), payment);
        if (order) {
          setOrderId(order.id);
        }
        fetchUserInfo(userId);
        fetchCartItems(userId);
        fetchCartTotal(userId);
      } catch (e) {
        message.error(getErrorMessage(e));
      }
    };
    if (userId && cartItems.length) {
      createOrder();
    }
  }, [userId]);

  return (
    <MainLayout>
      <Row justify="center" style={{ marginTop: "6rem" }}>
        <Space direction="vertical" align="center" size="small">
          <div style={{ textAlign: "center" }}>
            <Title className="ff-title success-color" level={size.width >= WindowWidth.Sx ? 1 : 3}>
              Thank you!
            </Title>
          </div>
          <div style={{ textAlign: "center" }}>
            <Title className="ff-title success-color" level={size.width >= WindowWidth.Sx ? 1 : 3}>
              Your order successfully created.
            </Title>
          </div>
          <div style={{ textAlign: "center" }}>
            <Title className="ff-title font-color" level={size.width >= WindowWidth.Sx ? 3 : 4}>
              You will be responded with the invoice soon.
            </Title>
          </div>
        </Space>
      </Row>
      <Row>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={{ offset: 1, span: 22 }}
          xl={{ offset: 2, span: 20 }}
          xxl={{ offset: 3, span: 18 }}
          style={{ padding: "20px 20px 0 20px" }}
        >
          <Order id={orderId} />
        </Col>
      </Row>
      <ReturnToStore />
    </MainLayout>
  );
};
