import doll from "../../../assets/img/doll.jpg";

import { useNavigate } from "react-router-dom";

import { useActions, useTypedSelector } from "../../../hooks";
import { RouteNames } from "../../../routes";
import { TypeNames } from "../../../consts";

export const BlockDolls = () => {
  const navigate = useNavigate();

  const { setSelectedType } = useActions();

  const {
    type: { types },
  } = useTypedSelector((state) => state);

  const handleGoToStore = () => {
    const type = types.find((type) => type.name === TypeNames.Dolls);
    if (type) {
      setSelectedType(type);
      navigate(RouteNames.SHOP);
    }
  };

  return (
    <section className="u-clearfix u-section-3" id="carousel_d219">
      <div className="u-clearfix u-sheet u-sheet-1">
        <div className="u-clearfix u-expanded-width u-layout-wrap u-layout-wrap-1">
          <div className="u-layout">
            <div className="u-layout-row">
              <div className="u-container-style u-layout-cell u-left-cell u-size-30 u-layout-cell-1">
                <div className="u-container-layout">
                  <div className="u-align-left u-border-5 u-border-palette-1-base u-left-0 u-shape u-shape-1"></div>
                  <div className="u-align-left u-left-0 u-opacity u-opacity-50 u-palette-1-base u-shape u-shape-2"></div>
                  <img
                    className="u-image u-image-1"
                    src={doll}
                    style={{ cursor: "pointer" }}
                    onClick={handleGoToStore}
                    alt="doll"
                  />
                </div>
              </div>
              <div className="u-align-left u-container-style u-layout-cell u-right-cell u-size-30 u-layout-cell-2">
                <div className="u-container-layout u-valign-middle u-container-layout-2">
                  <h1 className="u-text u-text-2 dancing-script">Waldorf Dolls</h1>
                  <div className="u-border-3 u-border-black u-line u-line-horizontal u-line-1"></div>
                  <p className="u-text u-text-2" style={{ fontSize: "1rem" }}>
                    Dolls made with 100% organic cotton and stuffed very firmly with clean organic wool . Head has been
                    sculpted with needle-felting techniques and her eyes embroidered.
                  </p>
                  <p className="u-text u-text-2" style={{ fontSize: "1rem" }}>
                    Hair mohair wig. The eyes are embroidered thread. The blush on her cheeks - wax crayons "Crayola"
                    (Certified non-toxic). All seams are made on a sewing machine, shoulders and head are sewn by hand.
                  </p>
                  <a onClick={handleGoToStore} className="u-link u-link-1">
                    to the store
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
