import React from "react";
import { Row, Typography } from "antd";
import { Link } from "react-router-dom";

import { RouteNames } from "../routes";

const { Text } = Typography;

export const ReturnToStore = () => {
  return (
    <Row justify="center" style={{ marginTop: "3rem" }}>
      <Text style={{ fontSize: "1.4rem", textAlign: "center" }}>
        <p>
          <Link to={RouteNames.SHOP}>Return to the store</Link>
        </p>
      </Text>
    </Row>
  );
};
