import React, { useCallback } from "react";
import { Button, Col, Descriptions, Row, Space, Tooltip, Typography } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FacebookOutlined, InstagramOutlined } from "@ant-design/icons";

import { Links, Shipping, SNColors, ThemeColors } from "../../consts";
import { RouteNames } from "../../routes";
import { useActions, useDeviceType } from "../../hooks";
import { useAppContext } from "../../providers";

const { Text } = Typography;

export const MainDescriptions = () => {
  const device = useDeviceType();
  const navigate = useNavigate();
  const location = useLocation();

  const { setAboutDollsClick, setAboutMeClick, setContactsClick } = useActions();
  const { aboutDollsRef, aboutMeRef, contactsRef } = useAppContext();

  const handleSubscribe = () => {
    navigate(RouteNames.SUBSCRIBE);
  };

  const calcNumberColums = useCallback(() => {
    return device.mobile ? 1 : device.tablet ? 3 : 4;
  }, [device.mobile, device.tablet]);

  const handleAboutDollsClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    if (location.pathname === RouteNames.LANDING && aboutDollsRef.current) {
      aboutDollsRef.current.scrollIntoView();
      setAboutDollsClick(false);
    } else {
      navigate(RouteNames.LANDING);
      setAboutDollsClick(true);
    }
  };

  const handleAboutMeClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    if (location.pathname === RouteNames.LANDING && aboutMeRef.current) {
      aboutMeRef.current.scrollIntoView();
      setAboutMeClick(false);
    } else {
      navigate(RouteNames.LANDING);
      setAboutMeClick(true);
    }
  };

  const handleContactsClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    if (location.pathname === RouteNames.LANDING && contactsRef.current) {
      contactsRef.current.scrollIntoView();
      setContactsClick(false);
    } else {
      navigate(RouteNames.LANDING);
      setContactsClick(true);
    }
  };

  return (
    <Row style={{ backgroundColor: ThemeColors.Footer }}>
      <Col
        xs={{ offset: 1, span: 22 }}
        sm={{ offset: 1, span: 22 }}
        md={{ offset: 1, span: 22 }}
        lg={{ offset: 2, span: 20 }}
        xl={{ offset: 4, span: 16 }}
        xxl={{ offset: 6, span: 12 }}
      >
        <Descriptions
          column={calcNumberColums()}
          colon={false}
          style={{ padding: "1rem 0" }}
          layout="vertical"
          labelStyle={{ fontWeight: 500, fontSize: "1.2rem", color: ThemeColors.FontColor, padding: "0 1rem" }}
          contentStyle={{ fontSize: ".9rem", color: ThemeColors.FontColor, padding: "0 1rem 1rem 1rem" }}
        >
          <Descriptions.Item label="Info & Contacts">
            <Space size={8} direction="vertical">
              <Link to="" onClick={handleAboutDollsClick}>
                About dolls
              </Link>
              <Link to="" onClick={handleAboutMeClick}>
                About me
              </Link>
              <Link to="" onClick={handleContactsClick}>
                Contacts
              </Link>
              <a href={Links.PrivacyPolicy}>Privacy policy</a>
            </Space>
          </Descriptions.Item>

          <Descriptions.Item label="Shipping">
            <Space size={4} direction="vertical">
              <Text>{`Within ${Shipping.Within} days of payment`}</Text>
              <Text>{`Takes approximately ${Shipping.Takes}+ days`}</Text>
            </Space>
          </Descriptions.Item>

          <Descriptions.Item label="Newsletter">
            <Space size={4} direction="vertical">
              <Text>Subscribe here, to get updates about new dolls, patterns and special offers!</Text>
              <Row align="middle" style={{ height: "4rem" }}>
                <Button
                  onClick={handleSubscribe}
                  style={{ backgroundColor: ThemeColors.Main, color: ThemeColors.FontColor }}
                >
                  Subscribe
                </Button>
              </Row>
            </Space>
          </Descriptions.Item>

          <Descriptions.Item label="Follow me">
            <Row style={{ fontSize: "2.4rem" }}>
              <Col
                span={12}
                style={{
                  color: SNColors.Facebook,
                  cursor: "pointer",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "start",
                }}
                onClick={() => window.open(Links.Facebook, "_self")}
              >
                <Tooltip title="Facebook">
                  <FacebookOutlined />
                </Tooltip>
              </Col>
              <Col
                offset={2}
                span={10}
                style={{
                  color: SNColors.Instagram,
                  cursor: "pointer",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "start",
                }}
                onClick={() => window.open(Links.Instagram, "_self")}
              >
                <Tooltip title="Instagram">
                  <InstagramOutlined />
                </Tooltip>
              </Col>
            </Row>
          </Descriptions.Item>
        </Descriptions>
      </Col>
    </Row>
  );
};
