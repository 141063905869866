import React from "react";
import { useNavigate } from "react-router-dom";
import { Alert, Button, Form, Modal, Row } from "antd";
import { RouteNames } from "../../routes";

type Props = {
  show: boolean;
  onShow: React.Dispatch<React.SetStateAction<boolean>>;
  onGuest: React.Dispatch<React.SetStateAction<boolean>>;
};

export const AuthDialog = ({ show, onShow, onGuest }: Props) => {
  const navigate = useNavigate();

  const handleRegister = () => {
    onShow(false);
    navigate(RouteNames.REGISTRATION);
  };

  const handleLogin = () => {
    onShow(false);
    navigate(RouteNames.LOGIN);
  };

  const handleGuest = () => {
    onShow(false);
    onGuest(true);
  };

  return (
    <Modal open={show} title="Please log in" centered footer={null} width={540} onCancel={() => onShow(false)}>
      <Form>
        <Form.Item
          labelCol={{ span: 14 }}
          wrapperCol={{ span: 10 }}
          label={<span className="auth-label">No account? Please</span>}
        >
          <Button type="primary" className="auth-button" onClick={handleRegister}>
            Register
          </Button>
        </Form.Item>
        <Form.Item
          labelCol={{ span: 14 }}
          wrapperCol={{ span: 10 }}
          label={<span className="auth-label">Already have account? Please</span>}
        >
          <Button type="primary" className="auth-button" onClick={handleLogin}>
            Log in
          </Button>
        </Form.Item>
        <Form.Item
          labelCol={{ span: 14 }}
          wrapperCol={{ span: 10 }}
          label={<span className="auth-label">Or continue as a</span>}
        >
          <Button type="primary" className="auth-button" onClick={handleGuest}>
            Guest
          </Button>
        </Form.Item>
        <Row justify="center" align="middle">
          <Alert
            description={
              <div className="auth-alert">Attention! Guests can't keep the cart content after logging out.</div>
            }
            type="error"
          />
        </Row>
      </Form>
    </Modal>
  );
};
