import { message } from "antd";
import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { UserService } from "../api";
import {
  BlockWelcome,
  BlockMenu,
  BlockDolls,
  BlockPatterns,
  BlockReviews,
  BlockOutfits,
  BlockAboutMe,
  BlockMessage,
  BlockAboutDolls,
} from "../components";
import { useActions, useAppDispatch, useTypedSelector } from "../hooks";
import { useAppContext } from "../providers";
import { RouteNames } from "../routes";
import { authorizeAsync, getErrorMessage } from "../utils";

export const Landing = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { setProcessing, setAboutDollsClick, setAboutMeClick, setContactsClick } = useActions();

  const [searchParams] = useSearchParams();

  const { aboutDollsRef, aboutMeRef, contactsRef } = useAppContext();
  const {
    util: { aboutDollsClicked, aboutMeClicked, contactsClicked },
  } = useTypedSelector((state) => state);

  useEffect(() => {
    const authorize = async () => {
      setProcessing(true);
      try {
        const userId = Number(searchParams.get("user"));
        if (userId) {
          const user = await UserService.fetchOne(userId);
          await authorizeAsync(dispatch, user);
          navigate(RouteNames.LANDING);
        }
      } catch (e) {
        message.error(getErrorMessage(e));
      } finally {
        setProcessing(false);
      }
    };
    authorize();
  }, []);

  useEffect(() => {
    if (aboutDollsClicked && aboutDollsRef.current) {
      setAboutDollsClick(false);
      aboutDollsRef.current.scrollIntoView();
    }

    if (aboutMeClicked && aboutMeRef.current) {
      setAboutMeClick(false);
      aboutMeRef.current.scrollIntoView();
    }

    if (contactsClicked && contactsRef.current) {
      setContactsClick(false);
      contactsRef.current.scrollIntoView();
    }
  }, []);

  return (
    <>
      <BlockWelcome />
      <BlockMenu />
      <BlockDolls />
      <div ref={aboutDollsRef}>
        <BlockAboutDolls />
      </div>
      <BlockOutfits />
      <BlockReviews />
      <BlockPatterns />
      <div ref={aboutMeRef}>
        <BlockAboutMe />
      </div>
      <div ref={contactsRef}>
        <BlockMessage />
      </div>
    </>
  );
};
