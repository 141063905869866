import React, { useEffect } from "react";
import { Button, Divider, Form, Input, message, Row, Space, Typography } from "antd";
import { useLocation, useNavigate, NavLink } from "react-router-dom";
import { GoogleOutlined, FacebookOutlined } from "@ant-design/icons";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

import { useAppDispatch, useTypedSelector, useActions, useFocusRef } from "../../hooks";
import { RouteNames } from "../../routes";
import { getErrorMessage, loginAsync } from "../../utils";
import { Modes, SNColors, ThemeColors, Urls, UserRoles } from "../../consts";
import { GoogleService, UserService } from "../../api";

const REACT_APP_API_URL = process.env.REACT_APP_API_URL || Urls.Api;
const { Title, Text } = Typography;

export const LoginForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const { setProcessing } = useActions();
  const {
    user: { isAuth },
    util: { processing },
  } = useTypedSelector((state) => state);

  const isLogin = location.pathname === RouteNames.LOGIN;

  const emailRef = useFocusRef([isLogin]);

  const google = () => {
    window.open(`${REACT_APP_API_URL}/api/auth/google`, "_self");
  };

  const facebook = () => {
    window.open(`${REACT_APP_API_URL}/api/auth/facebook`, "_self");
  };

  const submit = async ({ email, password }: any) => {
    setProcessing(true);
    try {
      if (process.env.NODE_ENV === Modes.Development) {
        if (!isLogin) await UserService.registration(email, email, password, UserRoles.User);
        await loginAsync(dispatch, email, password);
      } else {
        if (!executeRecaptcha) {
          throw new Error("Execute reCaptcha not available yet!");
        }

        const token = await executeRecaptcha("login");
        const response = await GoogleService.verifyReCaptcha(token);

        if (response.success && response.score > 0.5) {
          if (!isLogin) await UserService.registration(email, email, password, UserRoles.User);
          await loginAsync(dispatch, email, password);
        } else {
          throw new Error("No enter! It looks like you are bot.");
        }
      }
    } catch (e) {
      message.error(getErrorMessage(e));
    } finally {
      setProcessing(false);
    }
  };

  useEffect(() => {
    if (isAuth) navigate(RouteNames.SHOP);
  }, [isAuth]);

  return (
    <Form form={form} onFinish={submit} layout="horizontal">
      <Title level={4} className="ff-title" style={{ color: ThemeColors.FontColor }}>
        {isLogin ? "Logging In" : "Signing Up"}
      </Title>

      <Form.Item>
        <Button
          style={{ backgroundColor: SNColors.Google, borderColor: SNColors.Google }}
          block
          type="primary"
          htmlType="button"
          onClick={google}
        >
          <GoogleOutlined />
          Google
        </Button>
      </Form.Item>

      <Form.Item>
        <Button
          style={{ backgroundColor: SNColors.Facebook, borderColor: SNColors.Facebook }}
          block
          type="primary"
          htmlType="button"
          onClick={facebook}
        >
          <FacebookOutlined />
          Facebook
        </Button>
      </Form.Item>

      <Divider />

      <Form.Item
        name="email"
        label="E-mail"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        rules={[
          {
            type: "email",
            message: "E-mail format is not valid",
          },
          {
            required: true,
            message: "Please enter your email",
          },
        ]}
      >
        <Input ref={emailRef} style={{ color: ThemeColors.FontColor }} placeholder="Please enter your email" />
      </Form.Item>

      <Form.Item
        name="password"
        label="Password"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        hasFeedback
        rules={[
          {
            required: true,
            message: "Please enter password",
          },
        ]}
      >
        <Input.Password style={{ color: ThemeColors.FontColor }} placeholder="Please enter password" />
      </Form.Item>

      {!isLogin && (
        <Form.Item
          name="confirm"
          label="Confirm"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          dependencies={["password"]}
          hasFeedback
          rules={[
            {
              required: true,
              message: "Please confirm the password",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("The two passwords that you entered do not match!"));
              },
            }),
          ]}
        >
          <Input.Password style={{ color: ThemeColors.FontColor }} placeholder="Please confirm the password" />
        </Form.Item>
      )}

      <Form.Item>
        {isLogin ? (
          <Space direction="vertical">
            <NavLink to={RouteNames.CONFIRM_EMAIL}>Forgot password?</NavLink>
            <Text style={{ color: ThemeColors.FontColor }}>
              No account? <NavLink to={RouteNames.REGISTRATION}>Please register!</NavLink>
            </Text>
          </Space>
        ) : (
          <Text style={{ color: ThemeColors.FontColor }}>
            Already have account? <NavLink to={RouteNames.LOGIN}>Please log in!</NavLink>
          </Text>
        )}
      </Form.Item>

      <Form.Item>
        <Row justify="end">
          <Button
            type="primary"
            htmlType="submit"
            loading={processing}
            style={{ color: ThemeColors.Main, fontWeight: 500 }}
          >
            {isLogin ? "Log In" : "Sign Up"}
          </Button>
        </Row>
      </Form.Item>
    </Form>
  );
};
