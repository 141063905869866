import React from "react";
import { Button, Form, Input, message, Row, Typography } from "antd";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import TextArea from "antd/lib/input/TextArea";

import { useTypedSelector, useActions, useWindowSize } from "../../hooks";
import { getErrorMessage } from "../../utils";
import { Modes, ThemeColors, WindowWidth } from "../../consts";
import { GoogleService, NotificationService } from "../../api";

const { Text } = Typography;

export const ContactsForm = () => {
  const size = useWindowSize();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const { setProcessing } = useActions();
  const {
    util: { processing },
  } = useTypedSelector((state) => state);

  const [form] = Form.useForm();

  const submit = async ({ name, email, msg }: any) => {
    setProcessing(true);
    try {
      const subject = `Message from: ${name}: <${email}>`;
      if (process.env.NODE_ENV === Modes.Development) {
        await NotificationService.contact(subject, msg);
        message.success("You successfully sent your message to the ElenaPaclinaDolls.");
      } else {
        if (!executeRecaptcha) {
          throw new Error("Execute reCaptcha not available yet!");
        }

        const token = await executeRecaptcha("contact");
        const response = await GoogleService.verifyReCaptcha(token);

        if (response.success && response.score > 0.5) {
          await NotificationService.contact(subject, msg);
          message.success("You successfully sent your message to the ElenaPaclinaDolls.");
        } else {
          throw new Error("No enter! It looks like you are bot.");
        }
      }
    } catch (e) {
      message.error(getErrorMessage(e));
    } finally {
      form.resetFields();
      setProcessing(false);
    }
  };

  return (
    <Form form={form} onFinish={submit} layout="horizontal" style={{ margin: size.width < WindowWidth.Lg ? "1em" : 0 }}>
      <Form.Item
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
        label={<Text style={{ color: ThemeColors.Main }}>Name</Text>}
        name="name"
        rules={[
          {
            required: true,
            message: "Please enter your name",
          },
        ]}
      >
        <Input style={{ color: ThemeColors.FontColor }} placeholder="Please enter your name" />
      </Form.Item>

      <Form.Item
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
        label={<Text style={{ color: ThemeColors.Main }}>E-mail</Text>}
        name="email"
        rules={[
          {
            type: "email",
            message: "E-mail format is not valid",
          },
          {
            required: true,
            message: "Please enter your email",
          },
        ]}
      >
        <Input style={{ color: ThemeColors.FontColor }} placeholder="Please enter your email" />
      </Form.Item>

      <Form.Item
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
        label={<Text style={{ color: ThemeColors.Main }}>Message</Text>}
        name="msg"
        rules={[
          {
            required: true,
            message: "Please enter your message",
          },
        ]}
      >
        <TextArea style={{ color: ThemeColors.FontColor }} rows={8} placeholder="Please enter your message" />
      </Form.Item>

      <Form.Item>
        <Row justify="end">
          <Button htmlType="submit" loading={processing} style={{ color: ThemeColors.FontColor }}>
            Submit
          </Button>
        </Row>
      </Form.Item>
    </Form>
  );
};
