export const BlockReviews = () => {
  return (
    <section className="u-clearfix u-section-5" id="sec-2070">
      <div className="u-clearfix u-sheet u-sheet-1">
        <div className="u-align-center u-container-style u-expanded-width u-group u-group-1">
          <div style={{ width: "100%", textAlign: "center", marginTop: 10 }}>
            <h2 className="u-text u-text-1 dancing-script">Happy Clients. That's What They Said....</h2>
          </div>
        </div>

        <div className="u-clearfix u-expanded-width u-gutter-30 u-layout-wrap u-layout-wrap-1">
          <div className="u-gutter-0 u-layout">
            <div className="u-layout-row">
              <div className="u-align-left u-container-style u-layout-cell u-left-cell u-size-20 u-size-20-md u-white u-layout-cell-1">
                <div className="u-container-layout u-container-layout-2">
                  <span className="u-icon u-icon-circle u-opacity u-opacity-60 u-text-palette-1-base u-icon-1">
                    <svg className="u-svg-link" preserveAspectRatio="xMidYMin slice" viewBox="0 0 95.333 95.332">
                      <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#svg-d6d8"></use>
                    </svg>
                    <svg className="u-svg-content" viewBox="0 0 95.333 95.332" x="0px" y="0px" id="svg-d6d8">
                      <g>
                        <g>
                          <path d="M30.512,43.939c-2.348-0.676-4.696-1.019-6.98-1.019c-3.527,0-6.47,0.806-8.752,1.793    c2.2-8.054,7.485-21.951,18.013-23.516c0.975-0.145,1.774-0.85,2.04-1.799l2.301-8.23c0.194-0.696,0.079-1.441-0.318-2.045    s-1.035-1.007-1.75-1.105c-0.777-0.106-1.569-0.16-2.354-0.16c-12.637,0-25.152,13.19-30.433,32.076    c-3.1,11.08-4.009,27.738,3.627,38.223c4.273,5.867,10.507,9,18.529,9.313c0.033,0.001,0.065,0.002,0.098,0.002    c9.898,0,18.675-6.666,21.345-16.209c1.595-5.705,0.874-11.688-2.032-16.851C40.971,49.307,36.236,45.586,30.512,43.939z"></path>
                          <path d="M92.471,54.413c-2.875-5.106-7.61-8.827-13.334-10.474c-2.348-0.676-4.696-1.019-6.979-1.019    c-3.527,0-6.471,0.806-8.753,1.793c2.2-8.054,7.485-21.951,18.014-23.516c0.975-0.145,1.773-0.85,2.04-1.799l2.301-8.23    c0.194-0.696,0.079-1.441-0.318-2.045c-0.396-0.604-1.034-1.007-1.75-1.105c-0.776-0.106-1.568-0.16-2.354-0.16    c-12.637,0-25.152,13.19-30.434,32.076c-3.099,11.08-4.008,27.738,3.629,38.225c4.272,5.866,10.507,9,18.528,9.312    c0.033,0.001,0.065,0.002,0.099,0.002c9.897,0,18.675-6.666,21.345-16.209C96.098,65.559,95.376,59.575,92.471,54.413z"></path>
                        </g>
                      </g>
                    </svg>
                  </span>
                  <p className="u-text u-text-3" style={{ fontSize: "1rem" }}>
                    Beautiful doll! The work was amazing-impeccable! Elena was great to work with. She kept me informed
                    every step of the way. She even let me know when the doll reached my city. I definitely recommend...
                  </p>
                </div>
              </div>
              <div className="u-align-left u-container-style u-layout-cell u-size-20 u-size-20-md u-white u-layout-cell-2">
                <div className="u-container-layout u-container-layout-2">
                  <span className="u-icon u-icon-circle u-opacity u-opacity-60 u-text-palette-1-base u-icon-1">
                    <svg className="u-svg-link" preserveAspectRatio="xMidYMin slice" viewBox="0 0 95.333 95.332">
                      <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#svg-741b"></use>
                    </svg>
                    <svg className="u-svg-content" viewBox="0 0 95.333 95.332" x="0px" y="0px" id="svg-741b">
                      <g>
                        <g>
                          <path d="M30.512,43.939c-2.348-0.676-4.696-1.019-6.98-1.019c-3.527,0-6.47,0.806-8.752,1.793    c2.2-8.054,7.485-21.951,18.013-23.516c0.975-0.145,1.774-0.85,2.04-1.799l2.301-8.23c0.194-0.696,0.079-1.441-0.318-2.045    s-1.035-1.007-1.75-1.105c-0.777-0.106-1.569-0.16-2.354-0.16c-12.637,0-25.152,13.19-30.433,32.076    c-3.1,11.08-4.009,27.738,3.627,38.223c4.273,5.867,10.507,9,18.529,9.313c0.033,0.001,0.065,0.002,0.098,0.002    c9.898,0,18.675-6.666,21.345-16.209c1.595-5.705,0.874-11.688-2.032-16.851C40.971,49.307,36.236,45.586,30.512,43.939z"></path>
                          <path d="M92.471,54.413c-2.875-5.106-7.61-8.827-13.334-10.474c-2.348-0.676-4.696-1.019-6.979-1.019    c-3.527,0-6.471,0.806-8.753,1.793c2.2-8.054,7.485-21.951,18.014-23.516c0.975-0.145,1.773-0.85,2.04-1.799l2.301-8.23    c0.194-0.696,0.079-1.441-0.318-2.045c-0.396-0.604-1.034-1.007-1.75-1.105c-0.776-0.106-1.568-0.16-2.354-0.16    c-12.637,0-25.152,13.19-30.434,32.076c-3.099,11.08-4.008,27.738,3.629,38.225c4.272,5.866,10.507,9,18.528,9.312    c0.033,0.001,0.065,0.002,0.099,0.002c9.897,0,18.675-6.666,21.345-16.209C96.098,65.559,95.376,59.575,92.471,54.413z"></path>
                        </g>
                      </g>
                    </svg>
                  </span>
                  <p className="u-text u-text-3" style={{ fontSize: "1rem" }}>
                    Camille arrived and I have been admiring her!! Shes SO lovely!! Im incredibly pleased with her.
                    Thank you for your lovely work!! I LOVE the embroidery work on Camilles bonnet (hat)!! and all the
                    little ribbon, lace, color pieces that pull the outfit together! Beautiful work..
                  </p>
                </div>
              </div>
              <div className="u-align-left u-container-style u-layout-cell u-right-cell u-size-20 u-size-20-md u-white u-layout-cell-3">
                <div className="u-container-layout u-container-layout-2">
                  <span className="u-icon u-icon-circle u-opacity u-opacity-60 u-text-palette-1-base u-icon-1">
                    <svg className="u-svg-link" preserveAspectRatio="xMidYMin slice" viewBox="0 0 95.333 95.332">
                      <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#svg-59ae"></use>
                    </svg>
                    <svg className="u-svg-content" viewBox="0 0 95.333 95.332" x="0px" y="0px" id="svg-59ae">
                      <g>
                        <g>
                          <path d="M30.512,43.939c-2.348-0.676-4.696-1.019-6.98-1.019c-3.527,0-6.47,0.806-8.752,1.793    c2.2-8.054,7.485-21.951,18.013-23.516c0.975-0.145,1.774-0.85,2.04-1.799l2.301-8.23c0.194-0.696,0.079-1.441-0.318-2.045    s-1.035-1.007-1.75-1.105c-0.777-0.106-1.569-0.16-2.354-0.16c-12.637,0-25.152,13.19-30.433,32.076    c-3.1,11.08-4.009,27.738,3.627,38.223c4.273,5.867,10.507,9,18.529,9.313c0.033,0.001,0.065,0.002,0.098,0.002    c9.898,0,18.675-6.666,21.345-16.209c1.595-5.705,0.874-11.688-2.032-16.851C40.971,49.307,36.236,45.586,30.512,43.939z"></path>
                          <path d="M92.471,54.413c-2.875-5.106-7.61-8.827-13.334-10.474c-2.348-0.676-4.696-1.019-6.979-1.019    c-3.527,0-6.471,0.806-8.753,1.793c2.2-8.054,7.485-21.951,18.014-23.516c0.975-0.145,1.773-0.85,2.04-1.799l2.301-8.23    c0.194-0.696,0.079-1.441-0.318-2.045c-0.396-0.604-1.034-1.007-1.75-1.105c-0.776-0.106-1.568-0.16-2.354-0.16    c-12.637,0-25.152,13.19-30.434,32.076c-3.099,11.08-4.008,27.738,3.629,38.225c4.272,5.866,10.507,9,18.528,9.312    c0.033,0.001,0.065,0.002,0.099,0.002c9.897,0,18.675-6.666,21.345-16.209C96.098,65.559,95.376,59.575,92.471,54.413z"></path>
                        </g>
                      </g>
                    </svg>
                  </span>
                  <p className="u-text u-text-3" style={{ fontSize: "1rem" }}>
                    Le fanciulle sono arrivate ❤️ sono proprio felice sono molto molto belle!!!! Grazie
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
