import { $authHost } from "./index";
import { IImage } from "../models/IImage";

export class ImageService {
  static async create(image: Omit<IImage, "id">): Promise<IImage> {
    const { data } = await $authHost.post<IImage>("api/image", image);
    return data;
  }

  static async delete(file: string): Promise<number> {
    const { data } = await $authHost.delete<number>("api/image", { data: { file } });
    return data;
  }

  static async fetchAllToDelete(): Promise<IImage[]> {
    const { data } = await $authHost.get<IImage[]>("api/image/clear");
    return data;
  }
}
