import {
  OrderActionEnum,
  SetOrdersAction,
  SetOrdersLoadingAction,
  SetOrdersPageAction,
  SetOrdersTotalCountAction,
} from "./types";
import { AppDispatch } from "../..";
import { IOrderRes } from "../../../models";
import { OrderService } from "../../../api";

export const OrderActionCreators = {
  setOrders: (orders: IOrderRes[]): SetOrdersAction => ({ type: OrderActionEnum.SET_ORDERS, payload: orders }),
  setOrdersPage: (page: number): SetOrdersPageAction => ({ type: OrderActionEnum.SET_ORDERS_PAGE, payload: page }),
  setOrdersTotalCount: (count: number): SetOrdersTotalCountAction => ({
    type: OrderActionEnum.SET_ORDERS_TOTAL_COUNT,
    payload: count,
  }),
  setOrdersLoading: (loading: boolean): SetOrdersLoadingAction => ({
    type: OrderActionEnum.SET_ORDERS_LOADING,
    payload: loading,
  }),

  fetchOrders: (params: { userId?: number; page?: number; limit?: number }) => async (dispatch: AppDispatch) => {
    const { userId, page, limit } = params;
    dispatch(OrderActionCreators.setOrders(await OrderService.fetchAll(userId, page, limit)));
  },
  fetchLoadingOrders: (params: { userId?: number; page?: number; limit?: number }) => async (dispatch: AppDispatch) => {
    const { userId, page, limit } = params;
    dispatch(OrderActionCreators.setOrdersLoading(true));
    try {
      dispatch(OrderActionCreators.setOrders(await OrderService.fetchAll(userId, page, limit)));
    } finally {
      dispatch(OrderActionCreators.setOrdersLoading(false));
    }
  },
  fetchOrdersTotalCount: (userId?: number) => async (dispatch: AppDispatch) => {
    dispatch(OrderActionCreators.setOrdersTotalCount(await OrderService.count(userId)));
  },

  fetchReviews: (params: { userId?: number; page?: number; limit?: number }) => async (dispatch: AppDispatch) => {
    const { userId, page, limit } = params;
    dispatch(OrderActionCreators.setOrders(await OrderService.fetchAllReviews(userId, page, limit)));
  },
  fetchLoadingReviews:
    (params: { userId?: number; page?: number; limit?: number }) => async (dispatch: AppDispatch) => {
      const { userId, page, limit } = params;
      dispatch(OrderActionCreators.setOrdersLoading(true));
      try {
        dispatch(OrderActionCreators.setOrders(await OrderService.fetchAllReviews(userId, page, limit)));
      } finally {
        dispatch(OrderActionCreators.setOrdersLoading(false));
      }
    },
  fetchReviewsTotalCount: () => async (dispatch: AppDispatch) => {
    dispatch(OrderActionCreators.setOrdersTotalCount(await OrderService.countReviews()));
  },
};
