import React, { useEffect } from "react";
import { ConfigProvider, Layout } from "antd";
import jwt_decode from "jwt-decode";

import { useActions, useTypedSelector } from "./hooks";
import { LocalStorageNames, ItemsPaginationSettings, ThemeColors } from "./consts";
import { MainFooter, CookieReminder, MainDescriptions, MainContent } from "./components";
import { IUser, IType } from "./models";
import { MainHeader } from "./components";
import { UserService } from "./api";

const App = () => {
  const { setUser, setIsAuth, setSelectedType, setItemsPage, logout } = useActions();

  const {
    type: { types, selectedType },
  } = useTypedSelector((state) => state);

  useEffect(() => {
    ConfigProvider.config({
      theme: {
        primaryColor: ThemeColors.Primary,
        errorColor: ThemeColors.ErrorColor,
        warningColor: ThemeColors.WarningColor,
        successColor: ThemeColors.SuccessColor,
        infoColor: ThemeColors.InfoColor,
      },
    });
  }, []);

  useEffect(() => {
    const checkTokenExists = async () => {
      const token = localStorage.getItem(LocalStorageNames.Token);
      if (token) {
        const user: IUser = jwt_decode(token);
        if (!(await UserService.exists(user.id))) {
          logout();
        } else {
          setUser({
            id: user.id,
            name: user.name,
            email: user.email,
            paypal: user.paypal,
            role: user.role,
            address: user.address,
            phone: user.phone,
          });
          setIsAuth(true);
        }
      } else setIsAuth(false);
    };

    checkTokenExists();
  }, []);

  useEffect(() => {
    if (types && types.length > 0) {
      const storedSelectedType = localStorage.getItem(LocalStorageNames.Type);
      if (storedSelectedType) {
        const selectedType = JSON.parse(storedSelectedType) as IType;
        setSelectedType(selectedType);
      } else {
        setSelectedType(types[0]);
      }
    }
  }, [types]);

  useEffect(() => {
    if (selectedType) {
      localStorage.setItem(LocalStorageNames.Type, JSON.stringify(selectedType));
      setItemsPage(ItemsPaginationSettings.Page);
    }
  }, [selectedType]);

  return (
    <Layout>
      <MainHeader />
      <MainContent />
      <MainDescriptions />
      <MainFooter />
      <CookieReminder open={!localStorage.getItem(LocalStorageNames.Cookie)} />
    </Layout>
  );
};

export default App;
