import { OrderAction, OrderActionEnum, IOrderState } from "./types";
import { OrdersPaginationSettings } from "./../../../consts";

const initialState: IOrderState = {
  orders: [],
  page: OrdersPaginationSettings.Page,
  totalCount: OrdersPaginationSettings.TotalCount,
  loading: false,
};

export default function orderReducer(state = initialState, action: OrderAction): IOrderState {
  switch (action.type) {
    case OrderActionEnum.SET_ORDERS:
      return { ...state, orders: action.payload };
    case OrderActionEnum.SET_ORDERS_PAGE:
      return { ...state, page: action.payload };
    case OrderActionEnum.SET_ORDERS_TOTAL_COUNT:
      return { ...state, totalCount: action.payload };
    case OrderActionEnum.SET_ORDERS_LOADING:
      return { ...state, loading: action.payload };
    default:
      return state;
  }
}
