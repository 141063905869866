import React, { useState } from "react";
import { Button, Col, Form, Input, message, Pagination, Row, Space } from "antd";
import ReactQuill from "react-quill";

import { useActions, useFocusRef, useTypedSelector } from "../../hooks";
import { SubscribersPaginationSettings, ThemeColors } from "../../consts";
import { getErrorMessage } from "../../utils";
import { SubscribeService } from "../../api";
import { AdminSubscribeList } from "..";

export const AdminSubscribers = () => {
  const [form] = Form.useForm();
  const { setSubscribersPage, setProcessing } = useActions();

  const {
    util: { processing },
    subscribe: { page, totalCount },
  } = useTypedSelector((state) => state);

  const [text, setText] = useState("");

  const subjectRef = useFocusRef();

  const handlePageChange = (page: number) => {
    setSubscribersPage(page);
  };

  const handleClearClick = () => {
    form.resetFields();
    setText("");
  };

  const submit = async ({ subject }: any) => {
    setProcessing(true);
    try {
      await SubscribeService.send(subject, text);
      message.success("You have successfully completed the mailing list.");
    } catch (e) {
      message.error(getErrorMessage(e));
    } finally {
      setProcessing(false);
    }
  };

  return (
    <>
      <Row>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={{ offset: 2, span: 10 }}
          xxl={{ offset: 4, span: 8 }}
          style={{ padding: "20px 20px 0 20px" }}
        >
          <Form form={form} onFinish={submit} layout="horizontal">
            <Form.Item
              name="subject"
              label="Subject"
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 20 }}
              rules={[
                {
                  required: true,
                  message: "Please enter subject",
                },
              ]}
            >
              <Input style={{ color: ThemeColors.FontColor }} ref={subjectRef} placeholder="Please enter subject" />
            </Form.Item>

            <Form.Item
              name="text"
              label="Text"
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 20 }}
              rules={[
                {
                  required: true,
                  message: "Please enter text",
                },
              ]}
            >
              <ReactQuill
                theme="snow"
                placeholder="Please enter text"
                value={text}
                onChange={setText}
                style={{ height: "100%" }}
              />
            </Form.Item>

            <Form.Item>
              <Row justify="end">
                <Space size="middle">
                  <Button onClick={handleClearClick}>Clear</Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={processing}
                    style={{ color: ThemeColors.Main, fontWeight: 500 }}
                  >
                    Send out
                  </Button>
                </Space>
              </Row>
            </Form.Item>
          </Form>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12} style={{ padding: "20px 20px 0 20px" }}>
          <AdminSubscribeList />
          <Row justify="end" style={{ padding: "20px 0" }}>
            <Pagination
              defaultCurrent={SubscribersPaginationSettings.Page}
              current={page}
              defaultPageSize={SubscribersPaginationSettings.Limit}
              pageSize={SubscribersPaginationSettings.Limit}
              total={totalCount}
              responsive={true}
              hideOnSinglePage={true}
              showSizeChanger={false}
              onChange={handlePageChange}
            />
          </Row>
        </Col>
      </Row>
    </>
  );
};
