import React, { DependencyList, useEffect, useRef } from "react";
import { InputRef } from "antd";

export const useFocusRef = (deps?: DependencyList) => {
  const ref = useRef<InputRef>(null);

  useEffect(() => {
    const timeout = setTimeout(() => {
      clearTimeout(timeout);
      if (ref.current) {
        ref.current.focus();
      }
    }, 1);
  }, deps ?? []);

  return ref;
};
