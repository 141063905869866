import React, { useEffect } from "react";
import { Col, Pagination, Row, Space } from "antd";

import { useTypedSelector, useActions, useWindowSize } from "../hooks";
import { ItemsPaginationSettings, OrdersPaginationSettings, WindowWidth } from "../consts";
import { ItemList, MainLayout, Reviews, TypeBar } from "../components";

export const Shop = () => {
  const size = useWindowSize();

  const { setItemsPage, setOrdersPage } = useActions();

  const {
    item: { page, totalCount },
  } = useTypedSelector((state) => state);

  const handleChange = (page: number) => {
    setItemsPage(page);
  };

  useEffect(() => {
    setItemsPage(ItemsPaginationSettings.Page);
    setOrdersPage(OrdersPaginationSettings.Page);
  }, []);

  return (
    <MainLayout>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={6} xxl={6} style={{ padding: "20px 20px 0 20px" }}>
          <Space direction="vertical" style={{ width: "100%" }}>
            <TypeBar />
            {size.width >= WindowWidth.Xl && <Reviews />}
          </Space>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={18} xxl={18} style={{ padding: "20px 20px 0 20px" }}>
          <ItemList />
          <Row justify="end">
            <Pagination
              defaultCurrent={ItemsPaginationSettings.Page}
              current={page}
              defaultPageSize={ItemsPaginationSettings.Limit}
              pageSize={ItemsPaginationSettings.Limit}
              total={totalCount}
              responsive={true}
              hideOnSinglePage={true}
              showSizeChanger={false}
              onChange={handleChange}
            />
          </Row>
          {size.width < WindowWidth.Xl && <Reviews />}
        </Col>
      </Row>
    </MainLayout>
  );
};
