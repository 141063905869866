export interface IRefs {
  aboutDollsRef: React.RefObject<HTMLDivElement>;
  aboutMeRef: React.RefObject<HTMLDivElement>;
  contactsRef: React.RefObject<HTMLDivElement>;
}

export interface IUtilState {
  processing: boolean;
  aboutDollsClicked: boolean;
  aboutMeClicked: boolean;
  contactsClicked: boolean;
}

export enum UtilActionEnum {
  SET_PROCESSING = "SET_PROCESSING",
  SET_ABOUT_DOLLS_CLICK = "SET_ABOUT_DOLLS_CLICK",
  SET_ABOUT_ME_CLICK = "SET_ABOUT_ME_CLICK",
  SET_CONTACTS_CLICK = "SET_CONTACTS_CLICK",
}

export interface SetProcessingAction {
  type: UtilActionEnum.SET_PROCESSING;
  payload: boolean;
}

export interface SetAboutDollsClickAction {
  type: UtilActionEnum.SET_ABOUT_DOLLS_CLICK;
  payload: boolean;
}

export interface SetAboutMeClickAction {
  type: UtilActionEnum.SET_ABOUT_ME_CLICK;
  payload: boolean;
}

export interface SetContactsClickAction {
  type: UtilActionEnum.SET_CONTACTS_CLICK;
  payload: boolean;
}

export type UtilAction =
  | SetProcessingAction
  | SetAboutDollsClickAction
  | SetAboutMeClickAction
  | SetContactsClickAction;
