import { CountryAction, CountryActionEnum, ICountryState } from "./types";

const initialState: ICountryState = {
  countries: [],
  selectedCountry: 0,
};

export default function countryReducer(state = initialState, action: CountryAction): ICountryState {
  switch (action.type) {
    case CountryActionEnum.SET_COUNTRIES:
      return { ...state, countries: action.payload };
    case CountryActionEnum.SET_SELECTED_COUNTRY:
      return { ...state, selectedCountry: action.payload };
    default:
      return state;
  }
}
