import React, { useEffect, useState } from "react";
import { Divider, Empty, Rate, Row, Table, Typography } from "antd";
import moment from "moment";
import { ColumnsType } from "antd/lib/table";
import TextArea from "antd/lib/input/TextArea";

import { useActions, useWindowSize, useTypedSelector } from "../hooks";
import { ThemeColors, WindowWidth } from "../consts";
import { IOrderItem, IOrderRes } from "../models";
import { AddEditReview, ColorSpot, Spinner } from ".";

const { Text } = Typography;

interface OrderDataType extends IOrderRes {
  key: number;
}
interface OrderItemDataType extends Omit<IOrderItem, "description" | "orderId"> {
  key: number;
}

export const OrderList = () => {
  const size = useWindowSize();
  const { fetchLoadingOrders, fetchOrdersTotalCount } = useActions();

  const {
    user: {
      user: { id: userId },
    },
    order: { orders, page, loading },
  } = useTypedSelector((state) => state);

  const [showAddReviewModal, setShowAddReviewModal] = useState<boolean>(false);
  const [orderId, setOrderId] = useState<number>(0);
  const [review, setReview] = useState<string | undefined>("");
  const [rate, setRate] = useState<number | undefined>(0);

  const handleAddReview = (record: OrderDataType) => {
    setOrderId(record.id);
    setReview(record.review);
    setRate(record.rate);
    setShowAddReviewModal(true);
  };

  const getMasterColumns = (): ColumnsType<OrderDataType> => {
    return [
      {
        title: "Orders",
        render: (record: OrderDataType) => (
          <>
            <Text strong style={{ color: ThemeColors.FontColor }}>
              recipient name:
            </Text>
            &nbsp;{record.name}
            <br />
            <Text strong style={{ color: ThemeColors.FontColor }}>
              recipient email:
            </Text>
            &nbsp;{record.email}
            <br />
            <Text strong style={{ color: ThemeColors.FontColor }}>
              invoice email:
            </Text>
            &nbsp;{record.paypal}
            <br />
            <Text strong style={{ color: ThemeColors.FontColor }}>
              phone:
            </Text>
            &nbsp;{record.phone}
            <br />
            <Text strong style={{ color: ThemeColors.FontColor }}>
              order date:
            </Text>
            &nbsp;{record.orderDate && moment(record.orderDate).format("DD MMMM YYYY")}
            <br />
            <Text strong style={{ color: ThemeColors.FontColor }}>
              send date:
            </Text>
            &nbsp;{record.sendDate && moment(record.sendDate).format("DD MMMM YYYY")}
            <br />
            <Text strong style={{ color: ThemeColors.FontColor }}>
              track number:
            </Text>
            &nbsp;{record.trackNumber}
            <br />
            <a onClick={() => handleAddReview(record)}>Leave review</a>
          </>
        ),
        responsive: ["xs"],
      },
      {
        title: "recipient name",
        dataIndex: "name",
        key: "name",
        responsive: ["sm"],
      },
      {
        title: "recipient email",
        dataIndex: "email",
        key: "email",
        responsive: ["sm"],
      },
      {
        title: "invoice email",
        dataIndex: "paypal",
        key: "paypal",
        responsive: ["sm"],
      },
      {
        title: "phone",
        dataIndex: "phone",
        key: "phone",
        responsive: ["sm"],
      },
      {
        title: "order date",
        dataIndex: "orderDate",
        key: "orderDate",
        responsive: ["sm"],
        render: (orderDate: Date) => <span>{orderDate && moment(orderDate).format("DD MMMM YYYY")}</span>,
      },
      {
        title: "send date",
        dataIndex: "sendDate",
        key: "sendDate",
        responsive: ["sm"],
        render: (sendDate: Date) => <span>{sendDate && moment(sendDate).format("DD MMMM YYYY")}</span>,
      },
      {
        title: "track number",
        dataIndex: "trackNumber",
        key: "trackNumber",
        responsive: ["sm"],
      },
      {
        key: "action",
        responsive: ["sm"],
        render: (record: OrderDataType) => <a onClick={() => handleAddReview(record)}>Leave review</a>,
      },
    ];
  };

  const getDetailsColumns = (): ColumnsType<OrderItemDataType> => {
    return [
      {
        title: "color",
        dataIndex: "colorId",
        key: "colorId",
        render: (colorId: number) => colorId && <ColorSpot colorId={colorId} />,
      },
      {
        title: "name",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "weight",
        dataIndex: "weight",
        key: "weight",
      },
      {
        title: "price",
        dataIndex: "price",
        key: "price",
        render: (price: number) => <span>{`$${price}`}</span>,
      },
    ];
  };

  const getMasterDataSource = (): OrderDataType[] => {
    return orders
      .sort((a, b) => (a.id > b.id ? 1 : a.id < b.id ? -1 : 0))
      .map((order) => {
        const {
          id,
          name,
          email,
          paypal,
          address,
          phone,
          orderDate,
          sendDate,
          trackNumber,
          shipping,
          review,
          rate,
          order_items,
        } = order;
        return {
          key: id,
          id,
          name,
          email,
          paypal,
          address,
          phone,
          orderDate,
          sendDate,
          trackNumber,
          shipping,
          review,
          rate,
          order_items,
        };
      });
  };

  const getDetailsDataSource = (record: OrderDataType): OrderItemDataType[] => {
    return record.order_items
      .sort((a, b) => (a.id > b.id ? 1 : a.id < b.id ? -1 : 0))
      .map((item) => {
        const { id, name, price, weight, colorId } = item;
        return { key: id, id, name, price, weight, colorId };
      });
  };

  const detailsExpandable = {
    expandedRowRender: (master: OrderDataType) => (
      <div style={{ backgroundColor: ThemeColors.Main }}>
        {master.rate && master.review && (
          <>
            <Row style={{ backgroundColor: ThemeColors.Main }}>
              <Rate value={master.rate} disabled />
            </Row>
            <Row style={{ backgroundColor: ThemeColors.Main }}>
              <TextArea
                rows={3}
                value={master.review}
                readOnly
                style={{
                  width: size.width >= WindowWidth.Sx ? "60%" : "100%",
                  color: ThemeColors.FontColor,
                  backgroundColor: ThemeColors.Main,
                }}
              />
            </Row>
            <Divider />
          </>
        )}
        <Table
          style={{ width: size.width >= WindowWidth.Sx ? "60%" : "100%" }}
          size="small"
          dataSource={getDetailsDataSource(master)}
          columns={getDetailsColumns()}
          pagination={false}
          summary={(detail) => {
            let totalPrice = master.shipping ?? 0;
            detail.forEach(({ price }) => {
              totalPrice += price ?? 0;
            });
            return (
              <>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}>
                    <Text style={{ color: ThemeColors.FontColor }}>Shipping</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1} />
                  <Table.Summary.Cell index={2} />
                  <Table.Summary.Cell index={3}>
                    <Text>{`$${master.shipping ?? 0}`}</Text>
                  </Table.Summary.Cell>
                </Table.Summary.Row>

                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}>
                    <Text strong style={{ color: ThemeColors.FontColor }}>
                      Total
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1} />
                  <Table.Summary.Cell index={2} />
                  <Table.Summary.Cell index={3}>
                    <Text strong type="danger">
                      {`$${totalPrice}`}
                    </Text>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </>
            );
          }}
        />
      </div>
    ),
  };

  useEffect(() => {
    fetchLoadingOrders({ userId, page });
    fetchOrdersTotalCount(userId);
  }, [page]);

  if (loading) {
    return <Spinner height={100} />;
  } else if (orders.length === 0) {
    return <Empty description="Nothing here" style={{ color: ThemeColors.FontColor }} />;
  }

  return (
    <>
      <Table
        showHeader={size.width >= WindowWidth.Sx}
        style={{ width: "100%" }}
        size="middle"
        expandable={detailsExpandable}
        dataSource={getMasterDataSource()}
        columns={getMasterColumns()}
        pagination={false}
      />
      <AddEditReview
        show={showAddReviewModal}
        onShow={setShowAddReviewModal}
        orderId={orderId}
        review={review}
        rate={rate}
      />
    </>
  );
};
