import React from "react";
import { PlusOutlined } from "@ant-design/icons";
import { message, Modal, Upload } from "antd";
import { RcFile, UploadChangeParam, UploadFile, UploadProps } from "antd/lib/upload/interface";
import { LocalStorageNames, Urls } from "../consts";
import { IImagesState } from "../intfs";

const REACT_APP_API_URL = process.env.REACT_APP_API_URL || Urls.Api;

type Props = {
  itemId: number;
  imagesState: IImagesState;
  setImagesState: React.Dispatch<React.SetStateAction<IImagesState>>;
  deleteImage: (file: UploadFile<any>) => void;
};

export const PictureWall = ({ itemId, imagesState, setImagesState, deleteImage }: Props) => {
  const getBase64 = (file: RcFile) => {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file!);
      reader.onload = () => resolve(String(reader.result));
      reader.onerror = (error) => reject(error);
    });
  };

  const handleCancel = () => setImagesState((state) => ({ ...state, previewVisible: false }));

  const handlePreview = async (file: UploadFile<any>) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj!);
    }

    setImagesState((state) => ({
      ...state,
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle: file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1),
    }));
  };

  const handleChange = (info: UploadChangeParam<UploadFile<any>>) => {
    setImagesState((state) => ({ ...state, fileList: info.fileList }));
  };

  const handleBeforeUpload = (file: RcFile) => {
    const isJpg = file.type === "image/jpeg";
    if (!isJpg) {
      message.error("You can only upload JPG files!");
    }
    return isJpg;
  };

  const handleRemove = (file: UploadFile<any>) => {
    deleteImage(file);
  };

  const { previewVisible, previewImage, fileList, previewTitle } = imagesState;

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const props: UploadProps<any> = {
    name: "img",
    headers: {
      authorization: `Bearer ${localStorage.getItem(LocalStorageNames.Token)}`,
    },
    data: { itemId },
    action: `${REACT_APP_API_URL}/api/image`,
    listType: "picture-card",
    fileList: fileList,
    onPreview: handlePreview,
    onChange: handleChange,
    beforeUpload: handleBeforeUpload,
    onRemove: handleRemove,
  };

  return (
    <>
      <Upload {...props}>{fileList.length >= 4 ? null : uploadButton}</Upload>
      <Modal centered open={previewVisible} title={previewTitle} footer={null} onCancel={handleCancel}>
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </>
  );
};
