import { Typography } from "antd";

import { ThemeColors } from "../../../consts";
import { ContactsForm } from "../..";

const { Title } = Typography;

export const BlockMessage = () => {
  return (
    <section className="u-clearfix u-image u-shading u-section-8" id="carousel_191b">
      <div className="u-clearfix u-sheet u-sheet-1">
        <div className="u-clearfix u-expanded-width-md u-expanded-width-sm u-expanded-width-xs u-layout-wrap u-layout-wrap-1">
          <div className="u-gutter-0 u-layout">
            <div className="u-layout-row">
              <div className="u-black u-container-style u-layout-cell u-left-cell u-right-cell u-size-60 u-layout-cell-1">
                <div className="u-container-layout u-valign-middle-sm u-valign-middle-xs">
                  <div className="u-align-center-md u-align-center-sm u-border-1 u-border-white u-container-style u-group u-group-1">
                    <div className="u-container-layout u-valign-middle-md u-valign-middle-sm u-valign-top-lg u-valign-top-xl u-valign-top-xs u-container-layout-2">
                      <Title
                        level={3}
                        className="ff-title"
                        style={{ color: ThemeColors.Main, textAlign: "center", margin: "2em 0" }}
                      >
                        If you want to contact to me please send me a message
                      </Title>
                      <ContactsForm />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
