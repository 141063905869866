import { UserAction, UserActionEnum, IUserState } from "./types";
import { IUser } from "../../../models/IUser";

const initialState: IUserState = {
  isAuth: false,
  user: {} as IUser,
};

export default function userReducer(state = initialState, action: UserAction): IUserState {
  switch (action.type) {
    case UserActionEnum.SET_AUTH:
      return { ...state, isAuth: action.payload };
    case UserActionEnum.SET_USER:
      return { ...state, user: action.payload };
    default:
      return state;
  }
}
