import React from "react";
import { Button, message, Modal, Row, Typography } from "antd";

import { useActions, useTypedSelector } from "../../hooks";
import { deleteImage, getErrorMessage } from "../../utils";
import { ItemsPaginationSettings, ThemeColors } from "../../consts";
import { IItem } from "../../models";
import { CartItemService, ItemService } from "../../api";

type Props = {
  show: boolean;
  onShow: React.Dispatch<React.SetStateAction<boolean>>;
  editedItem: IItem;
  setEditedItem: React.Dispatch<React.SetStateAction<IItem>>;
  admin?: boolean;
};

const { Text } = Typography;

export const ConfirmDeleteItem = ({ show, onShow, editedItem, setEditedItem, admin }: Props) => {
  const { fetchItems, fetchAdminItems, fetchCartItems, setItemsPage, fetchTotalCount, fetchAdminTotalCount } =
    useActions();

  const {
    user: {
      user: { id: userId },
    },
    item: { items, page, totalCount },
    type: { selectedType },
  } = useTypedSelector((state) => state);

  const countNextPage = (): number => {
    const itemCount = totalCount - 1 - (page - 1) * ItemsPaginationSettings.Limit;
    return itemCount === 0 ? (totalCount - 1 > 0 ? page - 1 : page) : page;
  };

  const handleDelete = async () => {
    try {
      let nextPage = countNextPage();
      setItemsPage(nextPage);
      await ItemService.delete(editedItem.id);
      if (selectedType) {
        deleteImages();
        if (admin) {
          fetchAdminItems({ typeId: selectedType.id, page: nextPage });
          fetchAdminTotalCount(selectedType.id);
        } else {
          fetchItems({ typeId: selectedType.id, page: nextPage });
          fetchTotalCount(selectedType.id);
        }
      }
      await CartItemService.clear();
      fetchCartItems(userId);
    } catch (e) {
      message.error(getErrorMessage(e));
    }
    closeDialog();
  };

  const closeDialog = () => {
    setEditedItem(null!);
    onShow(false);
  };

  const deleteImages = () => {
    const item = items.find((item) => item.id === editedItem.id);
    if (item) {
      item.images.forEach((image) => {
        deleteImage(image.file);
      });
    }
  };

  return (
    <Modal
      open={show}
      title="Delete Item"
      centered
      closable={false}
      footer={[
        <Button
          key="no"
          type="primary"
          onClick={closeDialog}
          style={{ color: ThemeColors.Main, fontWeight: 500, minWidth: 54 }}
        >
          No
        </Button>,
        <Button key="yes" onClick={handleDelete} style={{ color: ThemeColors.FontColor }}>
          Yes
        </Button>,
      ]}
    >
      <Row justify="center">
        <Text>{`Are sure you want to delete the item "${editedItem?.name}"?`}</Text>
      </Row>
      <Row justify="center">
        <Text style={{ color: "red" }}>It will remove all such items from a cart as well.</Text>
      </Row>
    </Modal>
  );
};
