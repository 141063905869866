import s from "./MainHeader.module.css";

import React from "react";
import { Layout, Space } from "antd";

import { useTypedSelector } from "../../../hooks";
import { HeaderCart, HeaderMenu, HeaderTitle } from "../..";

const { Header } = Layout;

export const MainHeader = () => {
  const {
    user: { isAuth },
  } = useTypedSelector((state) => state);

  return (
    <Header className={s.header}>
      <header className="u-clearfix u-header">
        <div className="u-clearfix u-sheet ">
          <HeaderTitle />
          <nav className="u-menu u-menu-1">
            <Space size="middle">
              {isAuth && <HeaderCart />}
              <HeaderMenu />
            </Space>
          </nav>
        </div>
      </header>
    </Header>
  );
};
