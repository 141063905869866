import React, { useEffect } from "react";
import { Button, DatePicker, DatePickerProps, Form, Input, message, Modal, Row, Space, Typography } from "antd";
import moment from "moment";

import { getErrorMessage } from "../../utils";
import { useActions, useTypedSelector } from "../../hooks";
import { ThemeColors } from "../../consts";
import { OrderService } from "../../api";

const { Title } = Typography;

type Props = {
  show: boolean;
  onShow: React.Dispatch<React.SetStateAction<boolean>>;
  orderId: number;
};

export const SetSendDate = ({ show, onShow, orderId }: Props) => {
  const [form] = Form.useForm();
  const { fetchOrders } = useActions();

  const {
    order: { page },
  } = useTypedSelector((state) => state);

  const closeDialog = () => {
    onShow(false);
  };

  const handleCancel = () => {
    closeDialog();
  };

  const submit = async ({ sendDate, trackNumber }: any) => {
    try {
      await OrderService.editSendDate(orderId, sendDate, trackNumber);
      fetchOrders({ page });
      message.success("Send date successfully updated.");
    } catch (e) {
      message.error(getErrorMessage(e));
    }
    closeDialog();
  };

  const handleSetSendDate: DatePickerProps["onChange"] = (date) => {
    form.setFieldsValue({
      sendDate: date,
    });
  };

  useEffect(() => {
    const fetchOrder = async (id: number) => {
      const order = await OrderService.fetchOne(id);
      form.setFieldsValue({
        sendDate: moment(),
        trackNumber: order.trackNumber,
      });
    };

    if (show) {
      fetchOrder(orderId);
    }
  }, [show]);

  return (
    <Modal open={show} title="Set send date & Track number" centered footer={false} onCancel={handleCancel}>
      <Form form={form} onFinish={submit} layout="horizontal">
        <Form.Item>
          <Row justify="center">
            <Title level={4} className="ff-title" style={{ textAlign: "center", color: ThemeColors.FontColor }}>
              Please enter the send date & track number.
            </Title>
          </Row>
        </Form.Item>

        <Form.Item
          name="sendDate"
          label="Send date"
          labelCol={{ span: 10 }}
          wrapperCol={{ span: 14 }}
          rules={[
            {
              required: true,
              message: "Please enter the send date",
            },
          ]}
        >
          <DatePicker defaultValue={moment()} onChange={handleSetSendDate} format="DD MMMM YYYY" />
        </Form.Item>

        <Form.Item
          name="trackNumber"
          label="Track number"
          labelCol={{ span: 10 }}
          wrapperCol={{ span: 14 }}
          rules={[
            {
              required: true,
              message: "Please enter the track number",
            },
          ]}
        >
          <Input placeholder="Please enter the track number" />
        </Form.Item>

        <Row justify="end">
          <Space>
            <Button onClick={handleCancel} style={{ color: ThemeColors.FontColor }}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit" style={{ color: ThemeColors.Main, fontWeight: 500, minWidth: 73 }}>
              OK
            </Button>
          </Space>
        </Row>
      </Form>
    </Modal>
  );
};
