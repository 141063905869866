import React, { useEffect, useState } from "react";
import { Button, Empty, Row, Space } from "antd";

import { useTypedSelector, useActions } from "../hooks";
import { ThemeColors, UserRoles } from "../consts";
import { CreateEditItem, Item, Spinner } from ".";

export const ItemList = () => {
  const { fetchLoadingItems, fetchTotalCount } = useActions();

  const {
    user: {
      user: { role },
    },
    item: { items, page, loading },
    type: { types, selectedType },
  } = useTypedSelector((state) => state);
  const isAdmin = role === UserRoles.Admin;

  const [showCreateEditModal, setShowCreateEditModal] = useState<boolean>(false);

  useEffect(() => {
    if (selectedType) {
      fetchLoadingItems({ typeId: selectedType.id, page });
      fetchTotalCount(selectedType.id);
    }
  }, [selectedType, page]);

  if (loading) {
    return <Spinner height={100} />;
  } else if (items.length === 0 && !isAdmin) {
    return <Empty description="Nothing here" style={{ color: ThemeColors.FontColor }} />;
  }

  return (
    <>
      {isAdmin && (
        <Button
          block
          danger
          type="dashed"
          disabled={types.length === 0}
          style={{ height: 50 }}
          onClick={() => setShowCreateEditModal(true)}
        >
          Create New Item
        </Button>
      )}

      <Row gutter={[10, 10]} style={{ marginBottom: "1rem" }}>
        {items.map((item) => (
          <Item key={item.id} item={item} />
        ))}
      </Row>

      <CreateEditItem show={showCreateEditModal} onShow={setShowCreateEditModal} />
    </>
  );
};
