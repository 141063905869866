import React from "react";

type Props = {
  colorId?: number;
};

type ColorSpotProps = {
  border: string;
  background: string;
};

const colorSpots: ColorSpotProps[] = [
  { border: "lightgrey", background: "lightgrey" },
  { border: "grey", background: "white" },
  { border: "#67be67", background: "#67be67" },
  { border: "grey", background: "#ffff52" },
  { border: "pink", background: "pink" },
  { border: "#59aefc", background: "#59aefc" },
];

export const ColorSpot = ({ colorId }: Props) => {
  const getColorSpot = (colorId?: number): ColorSpotProps => {
    return colorId && colorId > 0 ? colorSpots[colorId - 1] : colorSpots[0];
  };

  return (
    <div
      style={{
        height: "2.15em",
        width: "2.2em",
        borderWidth: 1,
        borderStyle: "solid",
        borderRadius: "50%",
        borderColor: getColorSpot(colorId).border,
        backgroundColor: getColorSpot(colorId).background,
      }}
    ></div>
  );
};
