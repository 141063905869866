import React, { useState } from "react";
import { Button, Drawer, Row, Space, Typography } from "antd";
import { useWindowSize } from "../hooks";
import { LocalStorageNames, WindowWidth } from "../consts";

const { Text } = Typography;

type Props = {
  open: boolean;
};

export const CookieReminder = (props: Props) => {
  const size = useWindowSize();

  const [open, setOpen] = useState<boolean>(props.open);

  const calcHeight = () => {
    if (size.width < WindowWidth.Sx) {
      return 120;
    } else if (size.width >= WindowWidth.Sx && size.width < WindowWidth.Md) {
      return 90;
    } else if (size.width >= WindowWidth.Md) {
      return 70;
    }
  };

  const handleClose = () => {
    setOpen(false);
    localStorage.setItem(LocalStorageNames.Cookie, "confirmed");
  };

  return (
    <Drawer placement="bottom" closable={false} open={open} key="cookie" height={calcHeight()}>
      <Row justify="center" style={{ height: "100%" }}>
        <Space>
          <Text>
            This site uses cookies to ensure that it gives you the best experience of being here. If you continue to use
            this site we will assume that you are happy with it.
          </Text>
          <Button onClick={handleClose}>Ok</Button>
        </Space>
      </Row>
    </Drawer>
  );
};
