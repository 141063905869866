import { IItem } from "../../../models/IItem";

export interface IItemState {
  items: IItem[];
  page: number;
  totalCount: number;
  loading: boolean;
}

export enum ItemActionEnum {
  SET_ITEMS = "SET_ITEMS",
  SET_ITEMS_PAGE = "SET_ITEMS_PAGE",
  SET_ITEMS_TOTAL_COUNT = "SET_ITEMS_TOTAL_COUNT",
  SET_ITEMS_LOADING = "SET_ITEMS_LOADING",
}

export interface SetItemsAction {
  type: ItemActionEnum.SET_ITEMS;
  payload: IItem[];
}

export interface SetItemsPageAction {
  type: ItemActionEnum.SET_ITEMS_PAGE;
  payload: number;
}

export interface SetItemsTotalCountAction {
  type: ItemActionEnum.SET_ITEMS_TOTAL_COUNT;
  payload: number;
}

export interface SetItemsLoadingAction {
  type: ItemActionEnum.SET_ITEMS_LOADING;
  payload: boolean;
}

export type ItemAction = SetItemsAction | SetItemsPageAction | SetItemsTotalCountAction | SetItemsLoadingAction;
