import React from "react";
import { Card, Col, Row, Typography } from "antd";
import { useWindowSize, useTypedSelector } from "../hooks";
import { ThemeColors, WindowWidth } from "../consts";

const { Title } = Typography;

export const CartTotal = () => {
  const size = useWindowSize();

  const {
    cart: { total },
  } = useTypedSelector((state) => state);

  return (
    <Row>
      <Col
        xs={24}
        sm={24}
        md={{ offset: 3, span: 18 }}
        lg={{ offset: 4, span: 16 }}
        xl={{ offset: 5, span: 14 }}
        xxl={{ offset: 6, span: 12 }}
        style={{ padding: 2 }}
      >
        <Card size="small" hoverable style={{ backgroundColor: ThemeColors.Footer }}>
          <Row>
            <Col offset={8} span={8} style={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
              <Title level={3} className="ff-title m0-title" style={{ color: ThemeColors.FontColor }}>
                Total:
              </Title>
            </Col>
            <Col
              offset={size.width >= WindowWidth.Sx ? 3 : 1}
              span={size.width >= WindowWidth.Sx ? 5 : 7}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Title
                level={3}
                className="ff-title m0-title"
                style={{ color: ThemeColors.PriceColor }}
              >{`$${total}`}</Title>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};
