import { CountryActionEnum, SetCountriesAction, SetSelectedCountryAction } from "./types";
import { AppDispatch } from "../..";
import { ICountry } from "../../../models";
import { CountryService } from "../../../api";

export const CountryActionCreators = {
  setCountries: (countries: ICountry[]): SetCountriesAction => ({
    type: CountryActionEnum.SET_COUNTRIES,
    payload: countries,
  }),

  setSelectedCountry: (country: number): SetSelectedCountryAction => ({
    type: CountryActionEnum.SET_SELECTED_COUNTRY,
    payload: country,
  }),

  fetchCountries: () => async (dispatch: AppDispatch) => {
    dispatch(CountryActionCreators.setCountries(await CountryService.fetchAll()));
  },
};
