import { UtilAction, UtilActionEnum, IUtilState } from "./types";

const initialState: IUtilState = {
  processing: false,
  aboutDollsClicked: false,
  aboutMeClicked: false,
  contactsClicked: false,
};

export default function userReducer(state = initialState, action: UtilAction): IUtilState {
  switch (action.type) {
    case UtilActionEnum.SET_PROCESSING:
      return { ...state, processing: action.payload };
    case UtilActionEnum.SET_ABOUT_DOLLS_CLICK:
      return { ...state, aboutDollsClicked: action.payload };
    case UtilActionEnum.SET_ABOUT_ME_CLICK:
      return { ...state, aboutMeClicked: action.payload };
    case UtilActionEnum.SET_CONTACTS_CLICK:
      return { ...state, contactsClicked: action.payload };
    default:
      return state;
  }
}
