import { $authHost } from "./index";
import { ICartItem } from "../models/ICartItem";

export class CartItemService {
  static async create(cartItem: Omit<ICartItem, "id">): Promise<ICartItem> {
    const { data } = await $authHost.post<ICartItem>("api/cart_item", cartItem);
    return data;
  }

  static async delete(id: number): Promise<number> {
    const { data } = await $authHost.delete<number>("api/cart_item", { data: { id } });
    return data;
  }

  static async clear(): Promise<number> {
    const { data } = await $authHost.delete<number>("api/cart_item/clear");
    return data;
  }

  static async fetchAll(cartId: number): Promise<ICartItem[]> {
    const { data } = await $authHost.get<ICartItem[]>(`api/cart_item/${cartId}`);
    return data;
  }

  static async count(cartId: number, itemId: number): Promise<number> {
    const { data } = await $authHost.get<number>(`api/cart_item/count/${cartId}/${itemId}`);
    return data;
  }
}
