import { UserActionEnum, SetAuthAction, SetUserAction } from "./types";
import { AppDispatch } from "../..";
import { LocalStorageNames } from "../../../consts";
import { IUser } from "../../../models";
import { UserService } from "../../../api";

export const UserActionCreators = {
  setIsAuth: (auth: boolean): SetAuthAction => ({ type: UserActionEnum.SET_AUTH, payload: auth }),
  setUser: (user: IUser): SetUserAction => ({ type: UserActionEnum.SET_USER, payload: user }),

  fetchUserInfo: (userId: number) => async (dispatch: AppDispatch) => {
    const user = await UserService.fetchOne(userId);
    const { id, name, email, paypal, role, address, phone } = user;
    dispatch(UserActionCreators.setUser({ id, name, email, paypal, role, address, phone }));
  },

  logout: () => async (dispatch: AppDispatch) => {
    localStorage.removeItem(LocalStorageNames.Token);
    dispatch(UserActionCreators.setUser({} as IUser));
    dispatch(UserActionCreators.setIsAuth(false));
  },
};
