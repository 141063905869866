import {
  SetSubscribersAction,
  SetSubscribersLoadingAction,
  SetSubscribersPageAction,
  SetSubscribersTotalCountAction,
  SubscribeActionEnum,
} from "./types";
import { AppDispatch } from "../..";
import { ISubscribe } from "../../../models";
import { SubscribeService } from "../../../api";

export const SubscribeActionCreators = {
  setSubscribers: (subscribers: ISubscribe[]): SetSubscribersAction => ({
    type: SubscribeActionEnum.SET_SUBSCRIBERS,
    payload: subscribers,
  }),
  setSubscribersPage: (page: number): SetSubscribersPageAction => ({
    type: SubscribeActionEnum.SET_SUBSCRIBERS_PAGE,
    payload: page,
  }),
  setSubscribersTotalCount: (count: number): SetSubscribersTotalCountAction => ({
    type: SubscribeActionEnum.SET_SUBSCRIBERS_TOTAL_COUNT,
    payload: count,
  }),
  setSubscribersLoading: (loading: boolean): SetSubscribersLoadingAction => ({
    type: SubscribeActionEnum.SET_SUBSCRIBERS_LOADING,
    payload: loading,
  }),

  fetchSubscribers: (page?: number, limit?: number) => async (dispatch: AppDispatch) => {
    dispatch(SubscribeActionCreators.setSubscribers(await SubscribeService.fetchAll(page, limit)));
  },
  fetchLoadingSubscribers: (page?: number, limit?: number) => async (dispatch: AppDispatch) => {
    dispatch(SubscribeActionCreators.setSubscribersLoading(true));
    try {
      dispatch(SubscribeActionCreators.setSubscribers(await SubscribeService.fetchAll(page, limit)));
    } finally {
      dispatch(SubscribeActionCreators.setSubscribersLoading(false));
    }
  },
  fetchSubscribersTotalCount: () => async (dispatch: AppDispatch) => {
    dispatch(SubscribeActionCreators.setSubscribersTotalCount(await SubscribeService.count()));
  },
};
