import React from "react";
import { Col, Layout, Row } from "antd";
import { ThemeColors } from "../../consts";

const { Content } = Layout;

export const MainLayout: React.FC = ({ children }) => {
  return (
    <Row style={{ backgroundColor: ThemeColors.Main }}>
      <Col
        xs={24}
        sm={24}
        md={24}
        lg={{ offset: 2, span: 20 }}
        xl={{ offset: 2, span: 20 }}
        xxl={{ offset: 2, span: 20 }}
      >
        <Content style={{ marginTop: "2rem", backgroundColor: ThemeColors.Main }}>{children}</Content>
      </Col>
    </Row>
  );
};
