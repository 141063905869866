import { $host } from ".";
import { ISendEmail } from "../types";

export class NotificationService {
  static async contact(subject: string, message: string): Promise<ISendEmail> {
    const {
      data: { info },
    } = await $host.post<ISendEmail>("api/notification/contact", { subject, message });
    return { info };
  }
}
