import { IUser } from "../../../models/IUser";

export interface IUserState {
  isAuth: boolean;
  user: IUser;
}

export enum UserActionEnum {
  SET_AUTH = "SET_AUTH",
  SET_USER = "SET_USER",
}

export interface SetAuthAction {
  type: UserActionEnum.SET_AUTH;
  payload: boolean;
}

export interface SetUserAction {
  type: UserActionEnum.SET_USER;
  payload: IUser;
}

export type UserAction = SetAuthAction | SetUserAction;
