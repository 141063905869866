import { message } from "antd";
import { AxiosError } from "axios";

import { AppDispatch, CartActionCreators, UserActionCreators } from "../store";
import { CartService, ImageService, UserService } from "../api";
import { IUser } from "../models";

export const getErrorMessage = (e: unknown): string => {
  let error = String(e);
  const err = e as AxiosError;
  if (err.response) {
    error = (err.response.data as any).message;
  }
  return error;
};

export const deleteImage = async (file: string) => {
  try {
    await ImageService.delete(file);
  } catch (e) {
    message.error(getErrorMessage(e));
  }
};

type LoginResponse = {
  userId?: number;
  cartId?: number;
};

export const loginAsync = async (dispatch: AppDispatch, email: string, password: string): Promise<LoginResponse> => {
  const user = await UserService.login(email, password);
  return await authorizeAsync(dispatch, user);
};

export const authorizeAsync = async (dispatch: AppDispatch, user: IUser): Promise<LoginResponse> => {
  const cartItems = await CartService.fetchItems(user.id);
  dispatch(
    UserActionCreators.setUser({
      id: user.id,
      name: user.name,
      email: user.email,
      paypal: user.paypal,
      role: user.role,
      address: user.address,
      phone: user.phone,
    })
  );
  dispatch(CartActionCreators.setCartItems(cartItems));
  dispatch(UserActionCreators.setIsAuth(true));
  return {
    userId: user.id,
    cartId: cartItems.cartId,
  };
};
