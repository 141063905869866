import jwt_decode from "jwt-decode";
import { $authHost, $host } from "./index";
import { LocalStorageNames } from "../consts";
import { ISendEmail, IToken } from "../types";
import { IUser } from "../models/IUser";

export class UserService {
  static async registration(name: string, email: string, password: string, role: string): Promise<IUser> {
    const {
      data: { token },
    } = await $host.post<IToken>("api/user/registration", { name, email, password, role });
    localStorage.setItem(LocalStorageNames.Token, token);
    return jwt_decode(token);
  }

  static async login(email: string, password: string): Promise<IUser> {
    const {
      data: { token },
    } = await $host.post<IToken>("api/user/login", { email, password });
    localStorage.setItem(LocalStorageNames.Token, token);
    return jwt_decode(token);
  }

  static async confirmEmail(email: string): Promise<ISendEmail> {
    const {
      data: { info },
    } = await $host.post<ISendEmail>("api/user/confirm-email", { email });
    return { info };
  }

  static async resetPassword(email: string, password: string, secret: string): Promise<IUser> {
    const {
      data: { token },
    } = await $host.post<IToken>("api/user/reset-password", { email, password, secret });
    localStorage.setItem(LocalStorageNames.Token, token);
    return jwt_decode(token);
  }

  static async edit(user: Omit<IUser, "password" | "role">): Promise<IUser> {
    const {
      data: { token },
    } = await $authHost.put<IToken>("api/user", user);
    localStorage.setItem(LocalStorageNames.Token, token);
    return jwt_decode(token);
  }

  static async exists(id: number): Promise<boolean> {
    const { data } = await $host.get<boolean>(`api/user/exists/${id}`);
    return data;
  }

  static async fetchOne(id: number): Promise<IUser> {
    const {
      data: { token },
    } = await $host.get<IToken>(`api/user/${id}`);
    localStorage.setItem(LocalStorageNames.Token, token);
    return jwt_decode(token);
  }
}
