import React, { useEffect, useState } from "react";
import { Alert, Button, Col, message, Row, Typography } from "antd";

import { useTypedSelector, useActions } from "../hooks";
import { DefCountry, Shipping, ThemeColors } from "../consts";
import { CartItem, CartShipping, CartTotal, ConfirmOrder, MainLayout, ReturnToStore, Spinner } from "../components";
import { UserService } from "../api";
import { useNavigate } from "react-router-dom";
import { RouteNames } from "../routes";

const { Title } = Typography;

export const Cart = () => {
  const navigate = useNavigate();
  const { fetchLoadingCart, fetchCountries, setSelectedCountry, logout } = useActions();

  const {
    user: {
      user: { id: userId },
    },
    cart: { items, shipping, loading },
    counrty: { selectedCountry },
  } = useTypedSelector((state) => state);

  const [showConfirmOrderModal, setShowConfirmOrderModal] = useState<boolean>(false);

  const handleBuy = async () => {
    if (!userId || (userId && !(await UserService.exists(userId)))) {
      message.error("Sorry, your profile is no longer relevant. Please log in again as a GUEST or register.");
      navigate(RouteNames.SHOP);
      logout();
    } else {
      setShowConfirmOrderModal(true);
    }
  };

  const handleCountryChange = (countryCode: number) => {
    setSelectedCountry(countryCode);
  };

  useEffect(() => {
    if (userId && selectedCountry) {
      fetchLoadingCart(userId, selectedCountry);
    }
  }, [userId, selectedCountry]);

  useEffect(() => {
    fetchCountries();
    setSelectedCountry(DefCountry.USA);
  }, []);

  if (loading) {
    return <Spinner height={100} />;
  }

  return (
    <MainLayout>
      {shipping !== Shipping.Error ? (
        <>
          <Row justify="center">
            <Title level={1} className="ff-title" style={{ color: ThemeColors.FontColor }}>{`Your cart${
              items.length === 0 ? " is empty" : ""
            }`}</Title>
          </Row>
          {items
            .sort((a, b) => (a.id > b.id ? 1 : a.id < b.id ? -1 : 0))
            .map((item) => (
              <CartItem key={item.id} cartItem={item} />
            ))}
          {items.length > 0 && (
            <>
              <CartShipping onCountryChange={handleCountryChange} />
              <CartTotal />
              <Row justify="center" style={{ margin: "2em 0" }}>
                <Button
                  style={{ fontSize: "1.6em", minWidth: "4em", minHeight: "2.2em" }}
                  type="primary"
                  onClick={handleBuy}
                >
                  Buy
                </Button>
              </Row>
            </>
          )}
          <ConfirmOrder show={showConfirmOrderModal} onShow={setShowConfirmOrderModal} />
        </>
      ) : (
        <>
          <Row align="middle" style={{ margin: "2em 0 2em 0" }}>
            <Col
              xs={{ offset: 2, span: 20 }}
              sm={{ offset: 2, span: 20 }}
              md={{ offset: 6, span: 12 }}
              lg={{ offset: 8, span: 8 }}
              xl={{ offset: 8, span: 8 }}
              xxl={{ offset: 9, span: 6 }}
            >
              <Alert
                message="We are really sorry. At the moment the shipping calculation service unavailable. Unfortunately the order can't be created. Please try again later."
                type="error"
                description="This is an error message about order creation."
                showIcon
              />
            </Col>
          </Row>
        </>
      )}
      <ReturnToStore />
    </MainLayout>
  );
};
