import React from "react";
import { Col, Row } from "antd";
import { LoginForm, MainLayout } from "../components";

export const Auth = () => {
  return (
    <MainLayout>
      <Row align="middle" style={{ margin: "8rem 0" }}>
        <Col
          xs={{ offset: 2, span: 20 }}
          sm={{ offset: 2, span: 20 }}
          md={{ offset: 6, span: 12 }}
          lg={{ offset: 7, span: 10 }}
          xl={{ offset: 8, span: 8 }}
          xxl={{ offset: 9, span: 6 }}
        >
          <LoginForm />
        </Col>
      </Row>
    </MainLayout>
  );
};
