import React from "react";
import { Card, Col, Row, Select, Typography } from "antd";
import { useWindowSize, useTypedSelector } from "../hooks";
import { DefCountry, ThemeColors, WindowWidth } from "../consts";

const { Title } = Typography;
const { Option } = Select;

type Props = {
  onCountryChange: (countryCode: number) => void;
};

export const CartShipping = ({ onCountryChange }: Props) => {
  const size = useWindowSize();

  const {
    cart: { shipping },
    counrty: { countries, selectedCountry },
  } = useTypedSelector((state) => state);

  return (
    <Row>
      <Col
        xs={24}
        sm={24}
        md={{ offset: 3, span: 18 }}
        lg={{ offset: 4, span: 16 }}
        xl={{ offset: 5, span: 14 }}
        xxl={{ offset: 6, span: 12 }}
        style={{ padding: 2 }}
      >
        <Card size="small" hoverable style={{ backgroundColor: ThemeColors.Shipping }}>
          <Row>
            <Col
              span={size.width >= WindowWidth.Sx ? 10 : 7}
              style={{ display: "flex", alignItems: "center", justifyContent: "end" }}
            >
              <Title level={3} className="ff-title m0-title" style={{ color: ThemeColors.FontColor }}>
                Shipping:
              </Title>
            </Col>
            <Col
              span={size.width >= WindowWidth.Sx ? 6 : 9}
              style={{ display: "flex", alignItems: "center", justifyContent: "end" }}
            >
              <Select
                defaultValue={DefCountry.USA}
                value={selectedCountry}
                onChange={(value) => onCountryChange(value)}
                style={{ minWidth: "6rem" }}
              >
                {countries.map((country) => (
                  <Option key={country.id} value={country.code} style={{ color: ThemeColors.FontColor }}>
                    {country.name}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col
              offset={size.width >= WindowWidth.Sx ? 3 : 1}
              span={size.width >= WindowWidth.Sx ? 5 : 7}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Title
                level={3}
                className="ff-title m0-title"
                style={{ color: ThemeColors.PriceColor }}
              >{`$${shipping}`}</Title>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};
