import React from "react";
import { Col, Pagination, Row } from "antd";

import { ReviewsPaginationSettings } from "../consts";
import { useActions, useTypedSelector } from "../hooks";
import { ReviewList } from ".";

export const Reviews = () => {
  const { setOrdersPage } = useActions();

  const {
    order: { page, totalCount },
  } = useTypedSelector((state) => state);

  const handlePageChange = (page: number) => {
    setOrdersPage(page);
  };

  return (
    <>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <ReviewList />
        </Col>
      </Row>
      <Row justify="end" style={{ padding: "20px 0 20px 20px" }}>
        <Pagination
          defaultCurrent={ReviewsPaginationSettings.Page}
          current={page}
          defaultPageSize={ReviewsPaginationSettings.Limit}
          pageSize={ReviewsPaginationSettings.Limit}
          total={totalCount}
          responsive={true}
          hideOnSinglePage={true}
          showSizeChanger={false}
          onChange={handlePageChange}
        />
      </Row>
    </>
  );
};
