import React, { useEffect } from "react";
import { Button, Form, message, Modal, Rate, Row, Space, Typography } from "antd";
import TextArea from "antd/lib/input/TextArea";

import { getErrorMessage } from "../../utils";
import { useActions, useTypedSelector } from "../../hooks";
import { ThemeColors } from "../../consts";
import { OrderService } from "../../api";

const { Title } = Typography;

type Props = {
  show: boolean;
  onShow: React.Dispatch<React.SetStateAction<boolean>>;
  orderId: number;
  review?: string;
  rate?: number;
};

export const AddEditReview = ({ show, onShow, orderId, review, rate }: Props) => {
  const [form] = Form.useForm();
  const { fetchOrders } = useActions();

  const {
    user: {
      user: { id: userId },
    },
    order: { page },
  } = useTypedSelector((state) => state);

  const closeDialog = () => {
    onShow(false);
  };

  const handleCancel = () => {
    closeDialog();
  };

  const submit = async ({ review, rate }: any) => {
    try {
      await OrderService.editReview(orderId, review, rate);
      fetchOrders({ userId, page });
      message.success("Your review successfully updated. Thank you!");
    } catch (e) {
      message.error(getErrorMessage(e));
    } finally {
      closeDialog();
    }
  };

  useEffect(() => {
    if (show) {
      form.setFieldsValue({
        review,
        rate,
      });
    }
  }, [show]);

  return (
    <Modal open={show} title="Review" centered closable footer={false} onCancel={handleCancel}>
      <Form form={form} onFinish={submit} layout="horizontal">
        <Form.Item>
          <Row justify="center">
            <Title level={4} className="ff-title" style={{ textAlign: "center", color: ThemeColors.FontColor }}>
              Please leave your review.
            </Title>
          </Row>
        </Form.Item>

        <Form.Item
          name="rate"
          label="Rate"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          rules={[
            {
              required: true,
              message: "Please set the rate value",
            },
          ]}
        >
          <Rate style={{ position: "relative", bottom: 22 }} />
        </Form.Item>

        <Form.Item
          name="review"
          label="Annotation"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          rules={[
            {
              required: true,
              message: "Please type the review text here",
            },
          ]}
        >
          <TextArea
            style={{ color: ThemeColors.FontColor }}
            rows={4}
            placeholder="Please type the review text here"
            showCount
            maxLength={255}
          />
        </Form.Item>

        <Row justify="end">
          <Space>
            <Button onClick={handleCancel} style={{ color: ThemeColors.FontColor }}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit" style={{ color: ThemeColors.Main, fontWeight: 500, minWidth: 73 }}>
              OK
            </Button>
          </Space>
        </Row>
      </Form>
    </Modal>
  );
};
