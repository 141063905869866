import React from "react";
import { Route, Routes } from "react-router-dom";

import { RouteNames } from ".";
import { useTypedSelector } from "../hooks";
import { UserRoles } from "../consts";
import {
  Admin,
  Auth,
  Cart,
  ConfirmEmail,
  Landing,
  PaymentSuccess,
  ResetPassword,
  Shop,
  Subscribe,
  UserProfile,
} from "../pages";

export const AppRouter = () => {
  const {
    user: {
      isAuth,
      user: { role },
    },
  } = useTypedSelector((state) => state);
  const isAdmin = role === UserRoles.Admin;

  return (
    <Routes>
      <Route path="*" element={<Landing />} />
      <Route path={RouteNames.LANDING} element={<Landing />} />
      <Route path={RouteNames.SHOP} element={<Shop />} />
      <Route path={RouteNames.CART} element={<Cart />} />
      <Route path={RouteNames.SUBSCRIBE} element={<Subscribe />} />
      <Route path={RouteNames.USER_PROFILE} element={<UserProfile />} />
      <Route path={RouteNames.PAYMENT_SUCCESS} element={<PaymentSuccess />} />
      <Route path={RouteNames.LOGIN} element={<Auth />} />
      <Route path={RouteNames.REGISTRATION} element={<Auth />} />
      <Route path={RouteNames.CONFIRM_EMAIL} element={<ConfirmEmail />} />
      <Route path={RouteNames.RESET_PASSWORD} element={<ResetPassword />} />
      {isAuth && isAdmin && <Route path={RouteNames.ADMIN} element={<Admin />} />}
    </Routes>
  );
};
