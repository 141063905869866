import { useWindowSize } from ".";
import { WindowWidth } from "../consts";

export type DeviceType = {
  mobile: boolean;
  tablet: boolean;
  desktop: boolean;
};

export const useDeviceType = (): DeviceType => {
  const size = useWindowSize();
  return {
    mobile: size.width < WindowWidth.Sx,
    tablet: size.width >= WindowWidth.Sx && size.width < WindowWidth.Lg,
    desktop: size.width >= WindowWidth.Lg,
  };
};
