import { UserActionCreators } from "./user/action-creators";
import { ItemActionCreators } from "./item/action-creators";
import { TypeActionCreators } from "./type/action-creators";
import { CartActionCreators } from "./cart/action-creators";
import { OrderActionCreators } from "./order/action-creators";
import { SubscribeActionCreators } from "./subscribe/action-creators";
import { UtilActionCreators } from "./util/action-creators";
import { CountryActionCreators } from "./country/action-creators";

export const allActionCreators = {
  ...UserActionCreators,
  ...ItemActionCreators,
  ...TypeActionCreators,
  ...CartActionCreators,
  ...OrderActionCreators,
  ...SubscribeActionCreators,
  ...UtilActionCreators,
  ...CountryActionCreators,
};
