import React from "react";
import { Col, Pagination, Row } from "antd";

import { AdminItemsPaginationSettings } from "../../consts";
import { useActions, useTypedSelector } from "../../hooks";
import { AdminItemList, AdminTypeBar } from "..";

export const AdminItems = () => {
  const { setItemsPage } = useActions();

  const {
    item: { page, totalCount },
  } = useTypedSelector((state) => state);

  const handlePageChange = (page: number) => {
    setItemsPage(page);
  };

  return (
    <>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={6} xxl={6} style={{ padding: "20px 20px 0 20px" }}>
          <AdminTypeBar />
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={18} xxl={18} style={{ padding: "20px 20px 0 20px" }}>
          <AdminItemList />
          <Row justify="end" style={{ padding: "20px 0" }}>
            <Pagination
              defaultCurrent={AdminItemsPaginationSettings.Page}
              current={page}
              defaultPageSize={AdminItemsPaginationSettings.Limit}
              pageSize={AdminItemsPaginationSettings.Limit}
              total={totalCount}
              responsive={true}
              hideOnSinglePage={true}
              showSizeChanger={false}
              onChange={handlePageChange}
            />
          </Row>
        </Col>
      </Row>
    </>
  );
};
