import { TypeActionEnum, SetSelectedTypeAction, SetTypesAction } from "./types";
import { AppDispatch } from "../..";
import { LocalStorageNames } from "../../../consts";
import { TypeService } from "../../../api";
import { IType } from "../../../models";

export const TypeActionCreators = {
  setTypes: (types: IType[]): SetTypesAction => ({ type: TypeActionEnum.SET_TYPES, payload: types }),
  setSelectedType: (type: IType): SetSelectedTypeAction => ({ type: TypeActionEnum.SET_SELECTED_TYPE, payload: type }),

  fetchTypes: () => async (dispatch: AppDispatch) => {
    const types = await TypeService.fetchAll();
    if (types.length === 0) {
      localStorage.removeItem(LocalStorageNames.Type);
    }
    dispatch(TypeActionCreators.setTypes(types));
  },
};
