import s from "./MainContent.module.css";
import { Layout } from "antd";
import { AppRouter } from "../../../routes";

const { Content } = Layout;

export const MainContent = () => {
  return (
    <Content className={s.mainContent}>
      <AppRouter />
    </Content>
  );
};
