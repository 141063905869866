import s from "./HeaderMenu.module.css";

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Divider, Popover } from "antd";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";

import { Links, UserNames, UserRoles } from "../../../../../consts";
import { useActions, useDeviceType, useTypedSelector } from "../../../../../hooks";
import { RouteNames } from "../../../../../routes";
import { IType } from "../../../../../models";
import { ConfirmLogout } from "../../../..";
import { useAppContext } from "../../../../../providers";

export const HeaderMenu = () => {
  const device = useDeviceType();
  const navigate = useNavigate();
  const location = useLocation();

  const { setSelectedType, fetchTypes, setAboutDollsClick, setAboutMeClick, setContactsClick } = useActions();
  const { aboutDollsRef, aboutMeRef, contactsRef } = useAppContext();

  const {
    user: {
      isAuth,
      user: { role: userRole, name: userName },
    },
    type: { types },
  } = useTypedSelector((state) => state);
  const isAdmin = userRole === UserRoles.Admin;

  const [showConfirmLogoutModal, setShowConfirmLogoutModal] = useState<boolean>(false);
  const [collapsed, setCollapsed] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleCollapsed = () => {
    setMenuOpen(true);
  };

  const handleClose = () => {
    setMenuOpen(false);
    setCollapsed(false);
  };

  const handleUser = () => {
    handleClose();
    navigate(RouteNames.USER_PROFILE);
  };

  const handleAdmin = () => {
    handleClose();
    navigate(RouteNames.ADMIN);
  };

  const handleLogout = () => {
    handleClose();
    setShowConfirmLogoutModal(true);
  };

  const handleLogin = () => {
    handleClose();
    navigate(RouteNames.LOGIN);
  };

  const handleRegistration = () => {
    handleClose();
    navigate(RouteNames.REGISTRATION);
  };

  const handleHomepage = () => {
    handleClose();
    navigate(RouteNames.LANDING);
  };

  const handleType = (type: IType) => {
    handleClose();
    setSelectedType(type);
    navigate(RouteNames.SHOP);
  };

  const handlePrivacyPolicy = () => {
    handleClose();
    window.open(Links.PrivacyPolicy, "_self");
  };

  const handleOpenChange = (open: boolean) => {
    setMenuOpen(open);
    setCollapsed(open);
  };

  const handleAboutDollsClick = () => {
    handleClose();
    if (location.pathname === RouteNames.LANDING && aboutDollsRef.current) {
      aboutDollsRef.current.scrollIntoView();
      setAboutDollsClick(false);
    } else {
      navigate(RouteNames.LANDING);
      setAboutDollsClick(true);
    }
  };

  const handleAboutMeClick = () => {
    handleClose();
    if (location.pathname === RouteNames.LANDING && aboutMeRef.current) {
      aboutMeRef.current.scrollIntoView();
      setAboutMeClick(false);
    } else {
      navigate(RouteNames.LANDING);
      setAboutMeClick(true);
    }
  };

  const handleContactsClick = () => {
    handleClose();
    if (location.pathname === RouteNames.LANDING && contactsRef.current) {
      contactsRef.current.scrollIntoView();
      setContactsClick(false);
    } else {
      navigate(RouteNames.LANDING);
      setContactsClick(true);
    }
  };

  useEffect(() => {
    fetchTypes();
  }, []);

  return (
    <>
      <Popover
        trigger="click"
        placement={device.tablet || device.mobile ? "bottomRight" : "bottom"}
        open={menuOpen}
        onOpenChange={handleOpenChange}
        content={
          <div style={{ width: 300 }}>
            <p className={s.menuItem} onClick={handleHomepage}>
              Homepage
            </p>
            {types.map((type) => {
              return (
                <p key={type.id} className={s.menuItem} onClick={() => handleType(type)}>
                  {type.name}
                </p>
              );
            })}
            <Divider />
            <p className={s.menuItem} onClick={handleAboutDollsClick}>
              About dolls
            </p>
            <p className={s.menuItem} onClick={handleAboutMeClick}>
              About me
            </p>
            <p className={s.menuItem} onClick={handleContactsClick}>
              Contacts
            </p>
            <p className={s.menuItem} onClick={handlePrivacyPolicy}>
              Privacy policy
            </p>
            <Divider />
            {isAuth ? (
              <>
                {userName && userName.toLowerCase() !== UserNames.Guest && (
                  <p className={s.menuItem} onClick={handleUser}>
                    {userName}
                  </p>
                )}
                {isAdmin && (
                  <p className={`${s.menuItem} ${s.admin}`} onClick={handleAdmin}>
                    Admin
                  </p>
                )}
                <p className={s.menuItem} onClick={handleLogout}>
                  Log Out
                </p>
              </>
            ) : (
              <>
                <p className={s.menuItem} onClick={handleLogin}>
                  Log In
                </p>
                <p className={s.menuItem} onClick={handleRegistration}>
                  Sign Up
                </p>
              </>
            )}
          </div>
        }
      >
        <Button type="ghost" onClick={toggleCollapsed} className={s.sandwichBtn}>
          {collapsed ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
        </Button>
      </Popover>

      <ConfirmLogout show={showConfirmLogoutModal} onShow={setShowConfirmLogoutModal} />
    </>
  );
};
