import { useEffect, useState } from "react";

const getSavedValue = <T>(key: string, initialValue: T): T => {
  const storedValue = localStorage.getItem(key);
  if (!storedValue) return initialValue;
  const savedObj = JSON.parse(storedValue);
  if (savedObj) return savedObj;
  if (initialValue instanceof Function) return initialValue();
  return initialValue;
};

export const useLocalStorage = <T>(key: string, initialValue: T): [T, React.Dispatch<React.SetStateAction<T>>] => {
  const [value, setValue] = useState<T>(() => {
    return getSavedValue<T>(key, initialValue);
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
    return () => {
      const storedValue = localStorage.getItem(key);
      if (storedValue) localStorage.removeItem(key);
    };
  }, [value]);

  return [value, setValue];
};
