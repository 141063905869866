import React, { useEffect } from "react";
import { Button, Col, Empty, Row } from "antd";
import { useActions, useTypedSelector } from "../../hooks";
import { ThemeColors } from "../../consts";

export const AdminTypeBar = () => {
  const { setSelectedType, fetchTypes } = useActions();

  const {
    type: { types, selectedType },
  } = useTypedSelector((state) => state);

  useEffect(() => {
    fetchTypes();
  }, []);

  if (types.length === 0) {
    return <Empty description="Nothing here" style={{ color: ThemeColors.FontColor }} />;
  }

  return (
    <>
      {types.map((type) => (
        <Row key={type.id}>
          <Col span={24}>
            <Button
              style={{ height: 50, fontSize: "1.4em", color: ThemeColors.FontColor }}
              type={type.id === selectedType?.id ? "primary" : "default"}
              block
              onClick={() => setSelectedType(type)}
            >
              {type.name}
            </Button>
          </Col>
        </Row>
      ))}
    </>
  );
};
