import React, { useEffect } from "react";
import { Button, Form, Input, message, Modal, Radio, Row, Space, Typography } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useNavigate } from "react-router-dom";

import { useActions, useFocusRef, useTypedSelector } from "../../hooks";
import { getErrorMessage } from "../../utils";
import { PaymentType, ThemeColors } from "../../consts";
import { RouteNames } from "../../routes";
import { OrderService, UserService } from "../../api";

const { Title } = Typography;

type Props = {
  show: boolean;
  onShow: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ConfirmOrder = ({ show, onShow }: Props) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const { setCartPayment } = useActions();

  const {
    user: {
      user: {
        id: userId,
        name: userName,
        email: userEmail,
        paypal: userPayPal,
        address: userAddress,
        phone: userPhone,
      },
    },
    cart: { items: cartItems, shipping },
  } = useTypedSelector((state) => state);

  const nameRef = useFocusRef([show]);

  const closeDialog = () => {
    onShow(false);
  };

  const handleCancel = () => {
    closeDialog();
  };

  const submit = ({ name, email, address, phone, paypal, payment }: any) => {
    setCartPayment(payment as PaymentType);
    const delay = setTimeout(async () => {
      clearTimeout(delay);
      try {
        await UserService.edit({ id: userId, name, email, address, phone, paypal });
        const check = await OrderService.check(userId);
        if (check) {
          navigate(RouteNames.PAYMENT_SUCCESS);
          // === Stripe routines ===
          // const items: ICheckoutItem[] = cartItems.map((item) => {
          //   const {
          //     item: { id, name, price, number },
          //   } = item;
          //   return { id, name, price, number };
          // });
          // const stripeUrl = await StripeService.checkout(items, shipping);
          // if (stripeUrl.length) {
          //   window.open(stripeUrl, "_self");
          // }
        }
      } catch (e) {
        message.error(getErrorMessage(e));
      }
    }, 100);
    closeDialog();
  };

  useEffect(() => {
    if (show) {
      form.setFieldsValue({
        name: userName,
        email: userEmail,
        paypal: userPayPal,
        address: userAddress,
        phone: userPhone,
        payment: PaymentType.PayPal,
      });
    }
  }, [show]);

  return (
    <Modal open={show} title="Order confirmation" centered closable footer={false} onCancel={handleCancel}>
      <Form form={form} onFinish={submit} layout="horizontal">
        <Form.Item>
          <Row justify="center">
            <Title level={4} className="ff-title" style={{ textAlign: "center", color: ThemeColors.FontColor }}>
              Please confirm the order. Add or correct the order's shipping info.
            </Title>
          </Row>
        </Form.Item>

        <Form.Item
          name="name"
          label="Recipient Name"
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 17 }}
          rules={[
            {
              required: true,
              message: "Please enter the recipient name",
            },
          ]}
        >
          <Input style={{ color: ThemeColors.FontColor }} placeholder="Please enter the recipient name" ref={nameRef} />
        </Form.Item>

        <Form.Item
          name="payment"
          label="Payment Type"
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 17 }}
          rules={[
            {
              required: true,
              message: "Please enter the payment type",
            },
          ]}
        >
          <Radio.Group>
            <Radio value={PaymentType.PayPal}>PayPal</Radio>
            <Radio value={PaymentType.Stripe}>VISA/Mastercard</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          name="paypal"
          label="Invoice E-mail"
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 17 }}
          rules={[
            {
              type: "email",
              message: "E-mail format is not valid",
            },
            {
              required: true,
              message: "Please enter the invoice E-mail (PayPal / VISA/Mastercard)",
            },
          ]}
        >
          <Input
            style={{ color: ThemeColors.FontColor }}
            placeholder="Please enter the invoice E-mail (PayPal / VISA/Mastercard)"
          />
        </Form.Item>

        <Form.Item
          name="address"
          label="Shipping Address"
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 17 }}
          rules={[
            {
              required: true,
              message: "Please enter the shipping address",
            },
          ]}
        >
          <TextArea style={{ color: ThemeColors.FontColor }} rows={4} placeholder="Please enter the shipping address" />
        </Form.Item>

        <Form.Item name="phone" label="Phone" labelCol={{ span: 7 }} wrapperCol={{ span: 17 }}>
          <Input style={{ color: ThemeColors.FontColor }} placeholder="Please enter contact phone number (optional)" />
        </Form.Item>

        <Row justify="end">
          <Space>
            <Button onClick={handleCancel} style={{ color: ThemeColors.FontColor }}>
              Cancel
            </Button>
            <Button style={{ color: ThemeColors.Main, fontWeight: 500, minWidth: 73 }} htmlType="submit" type="primary">
              OK
            </Button>
          </Space>
        </Row>
      </Form>
    </Modal>
  );
};
