import axios, { AxiosRequestConfig } from "axios";
import { LocalStorageNames, Urls } from "../consts";

const $host = axios.create({
  baseURL: process.env.REACT_APP_API_URL || Urls.Api,
});

const $authHost = axios.create({
  baseURL: process.env.REACT_APP_API_URL || Urls.Api,
});

const authInterceptor = (config: AxiosRequestConfig) => {
  config.headers!.Authorization = `Bearer ${localStorage.getItem(LocalStorageNames.Token)}`;
  return config;
};

$authHost.interceptors.request.use(authInterceptor);

export { $host, $authHost };

export * from "./CartItemService";
export * from "./CartService";
export * from "./CountryService";
export * from "./GoogleService";
export * from "./ImageService";
export * from "./ItemService";
export * from "./NotificationService";
export * from "./OrderItemService";
export * from "./OrderService";
export * from "./StripeService";
export * from "./SubscribeService";
export * from "./TypeService";
export * from "./UserService";
