import React from "react";
import { Button, Form, Input, message, Row, Typography } from "antd";

import { useTypedSelector, useActions, useFocusRef } from "../../hooks";
import { getErrorMessage } from "../../utils";
import { ThemeColors } from "../../consts";
import { UserService } from "../../api";
import { ReturnToStore } from "..";

const { Title, Text } = Typography;

export const ConfirmEmailForm = () => {
  const [form] = Form.useForm();

  const { setProcessing } = useActions();
  const {
    util: { processing },
  } = useTypedSelector((state) => state);

  const emailRef = useFocusRef();

  const submit = async ({ email }: any) => {
    setProcessing(true);
    try {
      await UserService.confirmEmail(email);
      message.success(
        "You have successfully confirmed your E-mail. Please check your mailbox to continue the password reset procedure."
      );
    } catch (e) {
      message.error(getErrorMessage(e));
    } finally {
      setProcessing(false);
    }
  };

  return (
    <>
      <Form form={form} onFinish={submit} layout="horizontal">
        <Title level={4} className="ff-title" style={{ color: ThemeColors.FontColor }}>
          E-mail Confirmation
        </Title>

        <Form.Item
          label="E-mail"
          name="email"
          rules={[
            {
              type: "email",
              message: "E-mail format is not valid",
            },
            {
              required: true,
              message: "Please enter your email",
            },
          ]}
        >
          <Input ref={emailRef} style={{ color: ThemeColors.FontColor }} placeholder="Please enter your email" />
        </Form.Item>

        <Form.Item>
          <Text style={{ color: ThemeColors.FontColor }}>
            Please enter your E-mail to start the password reset procedure.
          </Text>
        </Form.Item>

        <Form.Item>
          <Row justify="end">
            <Button
              type="primary"
              htmlType="submit"
              loading={processing}
              style={{ color: ThemeColors.Main, fontWeight: 500 }}
            >
              Submit
            </Button>
          </Row>
        </Form.Item>
      </Form>
      <ReturnToStore />
    </>
  );
};
