import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunk from "redux-thunk";
import reducers from "./reducers";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const rootReducer = combineReducers(reducers);

export const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export * from "./reducers/cart/action-creators";
export * from "./reducers/country/action-creators";
export * from "./reducers/item/action-creators";
export * from "./reducers/order/action-creators";
export * from "./reducers/subscribe/action-creators";
export * from "./reducers/type/action-creators";
export * from "./reducers/user/action-creators";
export * from "./reducers/util/action-creators";
