import React, { useEffect } from "react";
import { Button, Form, Input, message, Row, Typography } from "antd";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useTypedSelector, useActions, useFocusRef, useAppDispatch } from "../../hooks";
import { getErrorMessage, loginAsync } from "../../utils";
import { ThemeColors } from "../../consts";
import { UserService } from "../../api";
import { ReturnToStore } from "../ReturnToStore";
import { RouteNames } from "../../routes";

const { Title, Text } = Typography;

export const ResetPasswordForm = () => {
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email") || "";
  const secret = searchParams.get("secret") || "";

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [form] = Form.useForm();

  const { setProcessing } = useActions();
  const {
    user: { isAuth },
    util: { processing },
  } = useTypedSelector((state) => state);

  const passwordRef = useFocusRef();

  const submit = async ({ password }: any) => {
    setProcessing(true);
    try {
      if (!email || !secret) {
        await message.error("Sorry. The reset password link is wrong. The password cannot be reset.");
      } else {
        await UserService.resetPassword(email, password, secret);
        await loginAsync(dispatch, email, password);
        await message.success(
          "Congratulations! You have reset your password and now you have successfully logged in to the site."
        );
      }
    } catch (e) {
      message.error(getErrorMessage(e));
    } finally {
      setProcessing(false);
    }
  };

  useEffect(() => {
    if (isAuth) navigate(RouteNames.SHOP);
  }, [isAuth]);

  return (
    <>
      <Form form={form} onFinish={submit} layout="horizontal">
        <Title level={4} className="ff-title" style={{ color: ThemeColors.FontColor }}>
          Password Reset
        </Title>

        <Form.Item
          name="password"
          label="Password"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          hasFeedback
          rules={[
            {
              required: true,
              message: "Please enter new password",
            },
          ]}
        >
          <Input.Password
            ref={passwordRef}
            style={{ color: ThemeColors.FontColor }}
            placeholder="Please enter new password"
          />
        </Form.Item>

        <Form.Item
          name="confirm"
          label="Confirm"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          dependencies={["password"]}
          hasFeedback
          rules={[
            {
              required: true,
              message: "Please confirm the password",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("The two passwords that you entered do not match!"));
              },
            }),
          ]}
        >
          <Input.Password style={{ color: ThemeColors.FontColor }} placeholder="Please confirm the password" />
        </Form.Item>

        <Form.Item>
          <Text style={{ color: ThemeColors.FontColor }}>Please enter and confirm your new password.</Text>
        </Form.Item>

        <Form.Item>
          <Row justify="end">
            <Button
              type="primary"
              htmlType="submit"
              loading={processing}
              style={{ color: ThemeColors.Main, fontWeight: 500 }}
            >
              Submit
            </Button>
          </Row>
        </Form.Item>
      </Form>
      <ReturnToStore />
    </>
  );
};
