import { Spin } from "antd";
import React from "react";

type Props = {
  height: number;
};

export const Spinner = ({ height }: Props) => {
  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height }}>
      <Spin size="large" />
    </div>
  );
};
