import {
  UtilActionEnum,
  SetProcessingAction,
  SetAboutDollsClickAction,
  SetAboutMeClickAction,
  SetContactsClickAction,
} from "./types";

export const UtilActionCreators = {
  setProcessing: (payload: boolean): SetProcessingAction => ({ type: UtilActionEnum.SET_PROCESSING, payload }),
  setAboutDollsClick: (payload: boolean): SetAboutDollsClickAction => ({
    type: UtilActionEnum.SET_ABOUT_DOLLS_CLICK,
    payload,
  }),
  setAboutMeClick: (payload: boolean): SetAboutMeClickAction => ({
    type: UtilActionEnum.SET_ABOUT_ME_CLICK,
    payload,
  }),
  setContactsClick: (payload: boolean): SetContactsClickAction => ({
    type: UtilActionEnum.SET_CONTACTS_CLICK,
    payload,
  }),
};
