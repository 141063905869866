import React, { useEffect } from "react";
import { Empty, Table } from "antd";

import { useActions, useTypedSelector } from "../../hooks";
import { ThemeColors } from "../../consts";
import { ISubscribe } from "../../models";
import { Spinner } from "..";

export const AdminSubscribeList = () => {
  const { fetchLoadingSubscribers, fetchSubscribersTotalCount } = useActions();

  const {
    subscribe: { subscribers, page, loading },
  } = useTypedSelector((state) => state);

  const getDataSource = (): ISubscribe[] => {
    return subscribers.map((subscribe) => {
      const { id, email } = subscribe;
      return { key: id, id, email };
    });
  };

  const getColumns = () => {
    return [
      {
        title: "id",
        dataIndex: "id",
        key: "id",
      },
      {
        title: "email",
        dataIndex: "email",
        key: "email",
      },
    ];
  };

  useEffect(() => {
    fetchLoadingSubscribers(page);
    fetchSubscribersTotalCount();
  }, [page]);

  if (loading) {
    return <Spinner height={100} />;
  } else if (subscribers.length === 0) {
    return <Empty description="Nothing here" style={{ color: ThemeColors.FontColor }} />;
  }

  return (
    <>
      <Table
        style={{ width: "100%" }}
        size="middle"
        dataSource={getDataSource()}
        columns={getColumns()}
        pagination={false}
      />
    </>
  );
};
