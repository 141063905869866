import user from "./user";
import item from "./item";
import type from "./type";
import cart from "./cart";
import order from "./order";
import subscribe from "./subscribe";
import util from "./util";
import counrty from "./country";

const reducers = {
  user,
  item,
  type,
  cart,
  order,
  subscribe,
  util,
  counrty,
};

export default reducers;
