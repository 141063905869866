import { useNavigate } from "react-router-dom";

import { TypeNames } from "../../../consts";
import { useActions, useTypedSelector } from "../../../hooks";
import { RouteNames } from "../../../routes";

export const BlockOutfits = () => {
  const navigate = useNavigate();

  const { setSelectedType } = useActions();

  const {
    type: { types },
  } = useTypedSelector((state) => state);

  const handleGoToStore = () => {
    const type = types.find((type) => type.name === TypeNames.Outfits);
    if (type) {
      setSelectedType(type);
      navigate(RouteNames.SHOP);
    }
  };

  return (
    <section className="u-clearfix u-image u-shading u-section-6" id="sec-3bf6">
      <div className="u-clearfix u-sheet u-sheet-1">
        <div className="u-clearfix u-expanded-width u-layout-wrap u-layout-wrap-1">
          <div className="u-layout">
            <div className="u-layout-row">
              <div className="u-container-style u-image u-layout-cell u-left-cell u-size-30 u-image-1">
                <div className="u-container-layout" style={{ cursor: "pointer" }} onClick={handleGoToStore}></div>
              </div>
              <div className="u-black u-container-style u-layout-cell u-right-cell u-size-30 u-layout-cell-2">
                <div className="u-container-layout">
                  <div className="u-border-1 u-border-white u-container-style u-group u-group-1">
                    <div className="u-container-layout u-valign-middle" style={{ padding: "0 20px" }}>
                      <h1 className="u-text u-text-1 u-text-color-3-light-3 dancing-script">Outfits</h1>
                      <div className="u-border-3 u-border-white u-line u-line-horizontal u-line-1"></div>
                      <p className="u-text u-text-2" style={{ fontSize: "1rem" }}>
                        Outfits for dolls 35-39cm and 42-45cm, sell here in this store and similar caliber. Wool,
                        cotton, embroidery, cotton lace, jersey, mohair, linen.
                      </p>
                      <a className="u-black u-button-style u-text-color-3-light-1" onClick={handleGoToStore}>
                        to the store
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
