import React, { useEffect } from "react";
import { Row } from "antd";

import { useActions, useTypedSelector } from "../hooks";
import { ReviewItem, Spinner } from ".";

export const ReviewList = () => {
  const { fetchLoadingReviews, fetchReviewsTotalCount } = useActions();

  const {
    order: { orders, page, loading },
  } = useTypedSelector((state) => state);

  useEffect(() => {
    fetchLoadingReviews({ page });
    fetchReviewsTotalCount();
  }, [page]);

  if (loading) {
    return <Spinner height={100} />;
  }

  return (
    <>
      <Row gutter={20}>
        {orders.map((item) => (
          <ReviewItem key={item.id} item={item} />
        ))}
      </Row>
    </>
  );
};
