import { $host, $authHost } from "./index";
import { OrdersPaginationSettings, PaymentType, ReviewsPaginationSettings } from "../consts";
import { IOrderRes } from "../models/IOrderRes";
import { IOrder } from "../models/IOrder";

export class OrderService {
  static async check(userId: number): Promise<boolean> {
    const { data } = await $authHost.post<boolean>("api/order/check", { userId });
    return data;
  }

  static async create(userId: number, shipping: number, orderDate: Date, payment: PaymentType): Promise<IOrder> {
    const { data } = await $authHost.post<IOrder>("api/order", { userId, shipping, orderDate, payment });
    return data;
  }

  static async fetchTotal(orderId: number): Promise<number> {
    const { data } = await $authHost.get<number>(`api/order/total/${orderId}`);
    return data;
  }

  static async editSendDate(id: number, sendDate?: Date, trackNumber?: string): Promise<number[]> {
    const { data } = await $authHost.put<number[]>("api/order/send_date", { id, sendDate, trackNumber });
    return data;
  }

  static async editReview(id: number, review: string, rate: number): Promise<number[]> {
    const { data } = await $authHost.put<number[]>("api/order/review", { id, review, rate });
    return data;
  }

  static async fetchOne(id: number): Promise<IOrderRes> {
    const { data } = await $authHost.get<IOrderRes>(`api/order/${id}`);
    return data;
  }

  static async fetchAll(
    userId?: number,
    page: number = OrdersPaginationSettings.Page,
    limit: number = OrdersPaginationSettings.Limit
  ): Promise<IOrderRes[]> {
    const { data } = await $authHost.get<IOrderRes[]>("api/order", {
      params: { userId, page, limit },
    });
    return data;
  }

  static async count(userId?: number): Promise<number> {
    const { data } = await $authHost.get<number>("api/order/count", { params: { userId } });
    return data;
  }

  static async fetchAllReviews(
    userId?: number,
    page: number = ReviewsPaginationSettings.Page,
    limit: number = ReviewsPaginationSettings.Limit
  ): Promise<IOrderRes[]> {
    const { data } = await $host.get<IOrderRes[]>("api/order/review", {
      params: { userId, page, limit },
    });
    return data;
  }

  static async countReviews(): Promise<number> {
    const { data } = await $host.get<number>("api/order/review/count");
    return data;
  }
}
