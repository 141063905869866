import React from "react";

import { Layout } from "antd";
import moment from "moment";

import { ThemeColors } from "../../../consts";

const { Footer } = Layout;

export const MainFooter = () => {
  return (
    <Footer
      style={{
        textAlign: "center",
        fontWeight: 400,
        fontSize: "1.1em",
        backgroundColor: ThemeColors.Footer,
        color: ThemeColors.FontColor,
      }}
    >
      ElenaPaclinaDolls © {moment().format("YYYY")}
    </Footer>
  );
};
