import { IType } from "../../../models/IType";

export interface ITypeState {
  types: IType[];
  selectedType: IType | null;
}

export enum TypeActionEnum {
  SET_TYPES = "SET_TYPES",
  SET_SELECTED_TYPE = "SET_SELECTED_TYPE",
}

export interface SetTypesAction {
  type: TypeActionEnum.SET_TYPES;
  payload: IType[];
}

export interface SetSelectedTypeAction {
  type: TypeActionEnum.SET_SELECTED_TYPE;
  payload: IType;
}

export type TypeAction = SetTypesAction | SetSelectedTypeAction;
