import { ItemAction, ItemActionEnum, IItemState } from "./types";
import { ItemsPaginationSettings } from "./../../../consts";

const initialState: IItemState = {
  items: [],
  page: ItemsPaginationSettings.Page,
  totalCount: ItemsPaginationSettings.TotalCount,
  loading: false,
};

export default function itemReducer(state = initialState, action: ItemAction): IItemState {
  switch (action.type) {
    case ItemActionEnum.SET_ITEMS:
      return { ...state, items: action.payload };
    case ItemActionEnum.SET_ITEMS_PAGE:
      return { ...state, page: action.payload };
    case ItemActionEnum.SET_ITEMS_TOTAL_COUNT:
      return { ...state, totalCount: action.payload };
    case ItemActionEnum.SET_ITEMS_LOADING:
      return { ...state, loading: action.payload };
    default:
      return state;
  }
}
