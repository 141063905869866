import aboutMe from "../../../assets/img/about_me.jpg";

export const BlockAboutMe = () => {
  return (
    <section className="u-clearfix u-section-7" id="sec-4607">
      <div className="u-clearfix u-sheet u-sheet-1">
        <div className="u-clearfix u-expanded-width u-layout-wrap u-layout-wrap-1">
          <div className="u-layout">
            <div className="u-layout-row">
              <div className="u-align-left u-container-style u-layout-cell u-left-cell u-size-27 u-layout-cell-1">
                <div style={{ padding: 30 }}>
                  <h1 className="u-text u-text-2 dancing-script">About Me</h1>
                  <div className="u-border-3 u-border-black u-line u-line-horizontal u-line-1"></div>
                  <p className="u-text u-text-2" style={{ fontSize: "1rem" }}>
                    Dear friend, if you are here, it means we have something in common. And this is a love for dolls,
                    especially in the Waldorf style. A little about myself, I'm from Russia and have been making textile
                    dolls since 2015. At first it was just a hobby, which gradually grew into love and a professional
                    occupation. Therefore, I fill all my time and thoughts by inventing new dolls and clothes for them.
                    From the very beginning, I only used my own patterns (I didn’t even buy patterns for testing).
                    Through trial and error, I got the result that you see now. I am very grateful to Maria Asenova, (we
                    do not know each other personally) whose dolls inspired me to create my own. I hope you like my
                    dolls as much as I love them.
                  </p>
                </div>
              </div>
              <div className="u-container-style u-layout-cell u-right-cell u-size-33 u-layout-cell-2">
                <div className="u-container-layout">
                  <div className="u-align-left u-border-5 u-border-palette-1-base u-left-0 u-shape u-shape-1"></div>
                  <div className="u-align-left u-left-0 u-opacity u-opacity-50 u-palette-1-base u-shape u-shape-2"></div>
                  <img className="u-image u-image-1" src={aboutMe} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
