export enum RouteNames {
  LANDING = "/",
  SHOP = "/shop",
  CART = "/cart",
  SUBSCRIBE = "/subscribe",
  USER_PROFILE = "/user_profile",
  PAYMENT_SUCCESS = "/payment_success",
  LOGIN = "/login",
  REGISTRATION = "/registration",
  ADMIN = "/admin",
  CONFIRM_EMAIL = "/confirm_email",
  RESET_PASSWORD = "/reset_password",
}

export * from "./AppRouter";
