import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "antd";
import { EditOutlined, CloseOutlined } from "@ant-design/icons";

import { useActions, useTypedSelector } from "../hooks";
import { ThemeColors, UserRoles } from "../consts";
import { IType } from "../models";
import { ConfirmDeleteType, CreateEditType } from ".";

export const TypeBar = () => {
  const { setSelectedType, fetchTypes } = useActions();

  const {
    user: {
      user: { role },
    },
    type: { types, selectedType },
  } = useTypedSelector((state) => state);
  const isAdmin = role === UserRoles.Admin;

  const [showCreateEditModal, setShowCreateEditModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [editedType, setEditedType] = useState<IType>(null!);

  const handleEdit = (type: IType) => {
    setEditedType(type);
    setShowCreateEditModal(true);
  };

  const handleDelete = (type: IType) => {
    setEditedType(type);
    setShowDeleteModal(true);
  };

  useEffect(() => {
    fetchTypes();
  }, []);

  return (
    <>
      {isAdmin && (
        <Button style={{ height: 50 }} type="dashed" danger block onClick={() => setShowCreateEditModal(true)}>
          Create New Type
        </Button>
      )}
      {types.map((type) => (
        <Row key={type.id}>
          <Col span={isAdmin ? 18 : 24}>
            <Button
              style={{
                height: 50,
                fontWeight: 500,
                fontSize: "1.4em",
                color: type.id === selectedType?.id ? ThemeColors.Main : ThemeColors.FontColorLight,
              }}
              type={type.id === selectedType?.id ? "primary" : "default"}
              block
              onClick={() => setSelectedType(type)}
            >
              {type.name}
            </Button>
          </Col>
          <Col span={isAdmin ? 3 : 0}>
            <Button
              type="dashed"
              danger
              block
              style={{ height: 50 }}
              icon={<EditOutlined />}
              onClick={() => handleEdit(type)}
            />
          </Col>
          <Col span={isAdmin ? 3 : 0}>
            <Button
              type="dashed"
              danger
              block
              style={{ height: 50 }}
              icon={<CloseOutlined />}
              onClick={() => handleDelete(type)}
            />
          </Col>
        </Row>
      ))}
      <CreateEditType
        show={showCreateEditModal}
        onShow={setShowCreateEditModal}
        editedType={editedType}
        setEditedType={setEditedType}
      />
      <ConfirmDeleteType
        show={showDeleteModal}
        onShow={setShowDeleteModal}
        editedType={editedType}
        setEditedType={setEditedType}
      />
    </>
  );
};
