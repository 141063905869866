import { ICountry } from "../../../models/ICountry";

export interface ICountryState {
  countries: ICountry[];
  selectedCountry: number;
}

export enum CountryActionEnum {
  SET_COUNTRIES = "SET_COUNTRIES",
  SET_SELECTED_COUNTRY = "SET_SELECTED_COUNTRY",
}

export interface SetCountriesAction {
  type: CountryActionEnum.SET_COUNTRIES;
  payload: ICountry[];
}

export interface SetSelectedCountryAction {
  type: CountryActionEnum.SET_SELECTED_COUNTRY;
  payload: number;
}

export type CountryAction = SetCountriesAction | SetSelectedCountryAction;
