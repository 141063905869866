import React from "react";
import { Col, Pagination, Row } from "antd";

import { OrdersPaginationSettings } from "../../consts";
import { useActions, useTypedSelector } from "../../hooks";
import { AdminOrderList } from "..";

export const AdminOrders = () => {
  const { setOrdersPage } = useActions();

  const {
    order: { page, totalCount },
  } = useTypedSelector((state) => state);

  const handlePageChange = (page: number) => {
    setOrdersPage(page);
  };

  return (
    <>
      <Row>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={{ offset: 1, span: 22 }}
          xl={{ offset: 2, span: 20 }}
          xxl={{ offset: 3, span: 18 }}
          style={{ padding: "20px 20px 0 20px" }}
        >
          <AdminOrderList />
          <Row justify="end" style={{ padding: "20px 0" }}>
            <Pagination
              defaultCurrent={OrdersPaginationSettings.Page}
              current={page}
              defaultPageSize={OrdersPaginationSettings.Limit}
              pageSize={OrdersPaginationSettings.Limit}
              total={totalCount}
              responsive={true}
              hideOnSinglePage={true}
              showSizeChanger={false}
              onChange={handlePageChange}
            />
          </Row>
        </Col>
      </Row>
    </>
  );
};
