import { PaymentType } from "../../../consts";
import { CartAction, CartActionEnum, ICartState } from "./types";

const initialState: ICartState = {
  id: 0,
  items: [],
  shipping: 0,
  payment: PaymentType.PayPal,
  total: 0,
  loading: false,
};

export default function cartReducer(state = initialState, action: CartAction): ICartState {
  switch (action.type) {
    case CartActionEnum.SET_CART_ITEMS:
      return { ...state, id: action.payload.cartId, items: action.payload.cartItems };
    case CartActionEnum.SET_CART_SHIPPING:
      return { ...state, shipping: action.payload };
    case CartActionEnum.SET_CART_PAYMENT:
      return { ...state, payment: action.payload };
    case CartActionEnum.SET_CART_TOTAL:
      return { ...state, total: action.payload };
    case CartActionEnum.SET_CART_LOADING:
      return { ...state, loading: action.payload };
    default:
      return state;
  }
}
