import { $authHost } from "./index";
import { ICart } from "../models/ICart";
import { ICartItemsRes } from "../models/ICartItemsRes";
import { IShippingRes } from "../models/IShippingRes";

export class CartService {
  static async create(cart: Omit<ICart, "id">): Promise<ICart> {
    const { data } = await $authHost.post<ICart>("api/cart", cart);
    return data;
  }

  static async delete(id: number): Promise<number> {
    const { data } = await $authHost.delete<number>("api/cart", { data: { id } });
    return data;
  }

  static async fetchItems(userId: number): Promise<ICartItemsRes> {
    const { data } = await $authHost.get<ICartItemsRes>(`api/cart/${userId}`);
    return data;
  }

  static async fetchTotal(userId: number): Promise<number> {
    const { data } = await $authHost.get<number>(`api/cart/total/${userId}`);
    return data;
  }

  static async fetchShipping(userId: number, countryCode: number): Promise<IShippingRes> {
    const { data } = await $authHost.get<IShippingRes>(`api/cart/shipping/${userId}/${countryCode}`);
    return data;
  }
}
