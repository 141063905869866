import React, { useEffect, useState } from "react";
import { Button, Card, Col, Collapse, Image, message, Row, Select, Space, Tooltip, Typography } from "antd";
import { ShoppingCartOutlined, EditOutlined, CloseOutlined } from "@ant-design/icons";

import { IItem } from "../models";
import { useAppDispatch, useTypedSelector, useActions, useWindowSize } from "../hooks";
import { ClothesColors, ThemeColors, Urls, UserNames, UserRoles, WindowWidth } from "../consts";
import { getErrorMessage, loginAsync } from "../utils";
import { CartItemService, UserService } from "../api";
import { AuthDialog, ColorSpot, ConfirmDeleteItem, CreateEditItem } from ".";

const REACT_APP_API_URL = process.env.REACT_APP_API_URL || Urls.Api;
const { Panel } = Collapse;
const { Title } = Typography;
const { Option } = Select;

type Props = {
  item: IItem;
};

export const Item = ({ item }: Props) => {
  const size = useWindowSize();
  const { name, price, number, description, hasColor, images } = item;

  const dispatch = useAppDispatch();
  const { fetchCartItems, setProcessing, logout } = useActions();

  const {
    user: {
      user: { id: userId, role },
      isAuth,
    },
    cart: { id: cartId, items: cartItems },
  } = useTypedSelector((state) => state);
  const isAdmin = role === UserRoles.Admin;

  const [preview, setPreview] = useState<boolean>(false);
  const [showCreateEditModal, setShowCreateEditModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showAuthModal, setShowAuthModal] = useState<boolean>(false);
  const [editedItem, setEditedItem] = useState<IItem>(null!);
  const [addingToCart, setAddingToCart] = useState<boolean>(false);
  const [guest, setGuest] = useState<boolean>(false);
  const [colorId, setColorId] = useState<number | undefined>(hasColor ? ClothesColors[0].value : undefined);

  const handleEdit = () => {
    setEditedItem(item);
    setShowCreateEditModal(true);
  };

  const handleDelete = () => {
    setEditedItem(item);
    setShowDeleteModal(true);
  };

  const handleAddToCart = async () => {
    if (!isAuth) {
      setGuest(false);
      setShowAuthModal(true);
    } else {
      addToCart(cartId, userId);
    }
  };

  const addToCart = async (cartId?: number, userId?: number) => {
    if (!userId || (userId && !(await UserService.exists(userId)))) {
      message.error("Sorry, your profile is no longer relevant. Please log in again as a GUEST or register.");
      logout();
    } else {
      setAddingToCart(true);
      const timeout = setTimeout(async () => {
        clearTimeout(timeout);
        try {
          const itemCount = await CartItemService.count(cartId!, item.id);
          if (itemCount < number!) {
            await CartItemService.create({ cartId, itemId: item.id, colorId });
          }
          if (userId) {
            fetchCartItems(userId);
          }
        } catch (e) {
          message.error(getErrorMessage(e));
        } finally {
          setAddingToCart(false);
        }
      }, 1000);
    }
  };

  const disableAddToCart = (itemId: number): boolean => {
    return cartItems.filter((cartItem) => cartItem.item.id === itemId).length >= number!;
  };

  useEffect(() => {
    const guestRegister = async () => {
      if (guest) {
        setProcessing(true);
        try {
          const name = UserNames.Guest.replace(/^\w/, (c) => c.toUpperCase());
          const email = String(`${UserNames.Guest}-${Date.now()}`);
          const password = UserNames.Guest;
          await UserService.registration(name, email, password, UserRoles.User);
          await loginAsync(dispatch, email, password);
        } catch (e) {
          message.error(getErrorMessage(e));
        } finally {
          setProcessing(false);
        }
      }
    };
    guestRegister();
  }, [guest]);

  return (
    <>
      <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
        <Card size="small" hoverable style={{ backgroundColor: ThemeColors.Main }}>
          <Image
            preview={{ visible: false }}
            width="100%"
            height={size.width < WindowWidth.Xl ? (size.width >= WindowWidth.Md ? 620 : "100%") : 350}
            style={{ objectFit: "cover" }}
            src={images.length > 0 ? `${REACT_APP_API_URL}/${images[0].file}` : "#"}
            onClick={() => setPreview(true)}
          />
          <div style={{ display: "none" }}>
            <Image.PreviewGroup preview={{ visible: preview, onVisibleChange: (visible) => setPreview(visible) }}>
              {images
                .sort((a, b) => (a.id > b.id ? 1 : a.id < b.id ? -1 : 0))
                .map((image) => (
                  <Image key={image.id} src={`${REACT_APP_API_URL}/${image.file}`} />
                ))}
            </Image.PreviewGroup>
          </div>

          <Space direction="vertical" style={{ width: "100%", marginTop: 6 }}>
            <Row>
              <Col span={18}>
                <Title
                  className="ff-title m0-title font-color-light"
                  style={{ maxWidth: "100%" }}
                  level={4}
                  ellipsis={{ rows: 1 }}
                >
                  {name}
                </Title>
              </Col>
              <Col span={6} style={{ display: "flex", justifyContent: "end" }}>
                <Title className="ff-title m0-title" style={{ color: "#d40000" }} level={4}>{`$${price}`}</Title>
              </Col>
            </Row>

            {hasColor && (
              <Row>
                <Col span={4}>
                  <Tooltip title="Add To Cart">
                    <Button
                      block
                      danger
                      loading={addingToCart}
                      icon={<ShoppingCartOutlined />}
                      style={{ backgroundColor: ThemeColors.Main }}
                      onClick={handleAddToCart}
                      disabled={disableAddToCart(item.id)}
                    />
                  </Tooltip>
                </Col>
                <Col offset={12} span={8} style={{ display: "flex", justifyContent: "end" }}>
                  <Space>
                    <ColorSpot colorId={colorId} />
                    <Select defaultValue={ClothesColors[0].value} onChange={(value) => setColorId(value)}>
                      {ClothesColors.map((cc) => (
                        <Option
                          key={cc.value}
                          value={cc.value}
                          style={{ backgroundColor: ThemeColors.Main, color: ThemeColors.FontColor }}
                        >{`${cc.value}. ${cc.name}`}</Option>
                      ))}
                    </Select>
                  </Space>
                </Col>
              </Row>
            )}

            {!hasColor && (
              <Row>
                <Col span={24}>
                  <Button
                    block
                    danger
                    loading={addingToCart}
                    icon={<ShoppingCartOutlined />}
                    style={{ backgroundColor: ThemeColors.Main }}
                    onClick={handleAddToCart}
                    disabled={disableAddToCart(item.id)}
                  >
                    Add To Cart
                  </Button>
                </Col>
              </Row>
            )}

            {isAdmin && (
              <Row gutter={10} align="middle">
                <Col span={12} style={{ display: "flex", justifyContent: "start" }}>
                  <Button block danger type="dashed" icon={<EditOutlined />} onClick={handleEdit}>
                    Edit
                  </Button>
                </Col>
                <Col span={12} style={{ display: "flex", justifyContent: "end" }}>
                  <Button block danger type="dashed" icon={<CloseOutlined />} onClick={handleDelete}>
                    Delete
                  </Button>
                </Col>
              </Row>
            )}

            <Collapse>
              <Panel header="Description" key={item.id}>
                <div dangerouslySetInnerHTML={{ __html: `${description}` }} />
              </Panel>
            </Collapse>
          </Space>
        </Card>
      </Col>
      <CreateEditItem
        show={showCreateEditModal}
        onShow={setShowCreateEditModal}
        editedItem={editedItem}
        setEditedItem={setEditedItem}
      />
      <ConfirmDeleteItem
        show={showDeleteModal}
        onShow={setShowDeleteModal}
        editedItem={editedItem}
        setEditedItem={setEditedItem}
      />
      <AuthDialog show={showAuthModal} onShow={setShowAuthModal} onGuest={setGuest} />
    </>
  );
};
