export const BlockAboutDolls = () => {
  return (
    <section className="u-clearfix u-section-5 u-group-2" id="sec-2070">
      <div className="u-clearfix u-sheet u-sheet-1">
        <div className="u-align-center u-container-style u-expanded-width u-group">
          <div style={{ width: "100%", textAlign: "center", marginTop: 10 }}>
            <h1 className="u-text u-text-1 dancing-script">About Dolls</h1>
          </div>
        </div>

        <div className="u-clearfix u-expanded-width u-gutter-30 u-layout-wrap u-layout-wrap-1">
          <div className="u-gutter-0 u-layout">
            <div className="u-layout-row">
              <p style={{ fontSize: "1rem" }}>
                A little about the Waldorf doll: This doll was specially designed by Waldorf school teachers for Waldorf
                kindergartens and schools. The source of the creation of the Waldorf doll and other Waldorf toys are
                folk rag dolls and research in the field of pedagogy based on the anthroposophical teaching of man.
              </p>
              <p style={{ fontSize: "1rem" }}>
                Sometimes Waldorf dolls are also called “Steiner dolls” after the founder of the Waldorf school, Rudolf
                Steiner. These toys are united by concern for the physical and spiritual health of the child, and an
                understanding of the need to take into account his natural development.
              </p>
              <p style={{ fontSize: "1rem" }}>
                The creation of Waldorf toys was initially aimed at creating a special pedagogical environment for the
                child, which would harmoniously influence his development. Over time, Waldorf dolls have changed. New
                techniques for making faces have been added (dry felting technique), which makes modern dolls more like
                children. It would be fair to call her a Waldorf-style doll.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
