import React, { useEffect, useState } from "react";
import { Divider, Empty, Table, Typography } from "antd";
import moment from "moment";
import { ColumnsType } from "antd/lib/table";

import { useActions, useTypedSelector, useWindowSize } from "../../hooks";
import { ThemeColors, WindowWidth } from "../../consts";
import { IOrderItem, IOrderRes } from "../../models";
import { ColorSpot, SetSendDate, Spinner } from "..";

const { Text } = Typography;

interface OrderDataType extends IOrderRes {}
interface OrderItemDataType extends Omit<IOrderItem, "number" | "description" | "orderId"> {}

export const AdminOrderList = () => {
  const size = useWindowSize();
  const { fetchLoadingOrders, fetchOrdersTotalCount } = useActions();

  const {
    order: { orders, page, loading },
  } = useTypedSelector((state) => state);

  const [showSetSendDateModal, setShowSetSendDateModal] = useState<boolean>(false);
  const [orderId, setOrderId] = useState<number>(0);

  const handleSetSendDate = (record: OrderDataType) => {
    setOrderId(record.id);
    setShowSetSendDateModal(true);
  };

  const getMasterColumns = (): ColumnsType<OrderDataType> => {
    return [
      {
        title: "Orders",
        render: (record: OrderDataType) => (
          <>
            <Text strong style={{ color: ThemeColors.FontColor }}>
              id:
            </Text>
            &nbsp;{record.id}
            <br />
            <Text strong style={{ color: ThemeColors.FontColor }}>
              name:
            </Text>
            &nbsp;{record.name}
            <br />
            <Text strong style={{ color: ThemeColors.FontColor }}>
              email:
            </Text>
            &nbsp;{record.email}
            <br />
            <Text strong style={{ color: ThemeColors.FontColor }}>
              invoice:
            </Text>
            &nbsp;{record.paypal}
            <br />
            <Text strong style={{ color: ThemeColors.FontColor }}>
              address:
            </Text>
            &nbsp;{record.address}
            <br />
            <Text strong style={{ color: ThemeColors.FontColor }}>
              phone:
            </Text>
            &nbsp;{record.phone}
            <br />
            <Text strong style={{ color: ThemeColors.FontColor }}>
              order date:
            </Text>
            &nbsp;{record.orderDate && moment(record.orderDate).format("DD MMMM YYYY")}
            <br />
            <Text strong style={{ color: ThemeColors.FontColor }}>
              send date:
            </Text>
            &nbsp;{record.sendDate && moment(record.sendDate).format("DD MMMM YYYY")}
            <br />
            <Text strong style={{ color: ThemeColors.FontColor }}>
              track number:
            </Text>
            &nbsp;{record.trackNumber}
            <br />
            <a onClick={() => handleSetSendDate(record)}>Set send date</a>
          </>
        ),
        responsive: ["xs"],
      },
      {
        title: "id",
        dataIndex: "id",
        key: "id",
        responsive: ["sm"],
      },
      {
        title: "name",
        dataIndex: "name",
        key: "name",
        responsive: ["sm"],
      },
      {
        title: "email",
        dataIndex: "email",
        key: "email",
        responsive: ["sm"],
      },
      {
        title: "invoice",
        dataIndex: "paypal",
        key: "paypal",
        responsive: ["sm"],
      },
      {
        title: "phone",
        dataIndex: "phone",
        key: "phone",
        responsive: ["sm"],
      },
      {
        title: "order date",
        dataIndex: "orderDate",
        key: "orderDate",
        responsive: ["sm"],
        render: (orderDate: Date) => <span>{orderDate && moment(orderDate).format("DD MMMM YYYY")}</span>,
      },
      {
        title: "send date",
        dataIndex: "sendDate",
        key: "sendDate",
        responsive: ["sm"],
        render: (sendDate: Date) => <span>{sendDate && moment(sendDate).format("DD MMMM YYYY")}</span>,
      },
      {
        title: "track number",
        dataIndex: "trackNumber",
        key: "trackNumber",
        responsive: ["sm"],
      },
      {
        key: "action",
        responsive: ["sm"],
        render: (record: OrderDataType) => <a onClick={() => handleSetSendDate(record)}>Set send date</a>,
      },
    ];
  };

  const getDetailsColumns = (): ColumnsType<OrderItemDataType> => {
    return [
      {
        title: "id",
        dataIndex: "id",
        key: "id",
      },
      {
        title: "color",
        dataIndex: "colorId",
        key: "colorId",
        render: (colorId: number) => colorId && <ColorSpot colorId={colorId} />,
      },
      {
        title: "name",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "weight",
        dataIndex: "weight",
        key: "weight",
      },
      {
        title: "price",
        dataIndex: "price",
        key: "price",
        render: (price: number) => <span>{`$${price}`}</span>,
      },
    ];
  };

  const getMasterDataSource = (): OrderDataType[] => {
    return orders
      .sort((a, b) => (a.id > b.id ? 1 : a.id < b.id ? -1 : 0))
      .map((order) => {
        const { id, name, email, paypal, address, phone, orderDate, sendDate, trackNumber, shipping, order_items } =
          order;
        return {
          key: id,
          id,
          name,
          email,
          paypal,
          address,
          phone,
          orderDate,
          sendDate,
          trackNumber,
          shipping,
          order_items,
        };
      });
  };

  const getDetailsDataSource = (record: OrderDataType): OrderItemDataType[] => {
    return record.order_items
      .sort((a, b) => (a.id > b.id ? 1 : a.id < b.id ? -1 : 0))
      .map((item) => {
        const { id, name, price, weight, colorId } = item;
        return { key: id, id, name, price, weight, colorId };
      });
  };

  const detailsExpandable = {
    expandedRowRender: (master: OrderDataType) => (
      <>
        <Text strong style={{ color: ThemeColors.FontColor }}>
          address:
        </Text>
        &nbsp;{master.address}
        <Divider />
        <Table
          style={{ width: size.width >= WindowWidth.Sx ? "60%" : "100%" }}
          size="small"
          dataSource={getDetailsDataSource(master)}
          columns={getDetailsColumns()}
          pagination={false}
          summary={(detail) => {
            let totalPrice = master.shipping ?? 0;
            detail.forEach(({ price }) => {
              totalPrice += price ?? 0;
            });
            return (
              <>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}>
                    <Text style={{ color: ThemeColors.FontColor }}>Shipping</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1} />
                  <Table.Summary.Cell index={2} />
                  <Table.Summary.Cell index={3} />
                  <Table.Summary.Cell index={4}>
                    <Text>{`$${master.shipping ?? 0}`}</Text>
                  </Table.Summary.Cell>
                </Table.Summary.Row>

                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}>
                    <Text strong style={{ color: ThemeColors.FontColor }}>
                      Total
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1} />
                  <Table.Summary.Cell index={2} />
                  <Table.Summary.Cell index={3} />
                  <Table.Summary.Cell index={4}>
                    <Text strong type="danger">
                      {`$${totalPrice}`}
                    </Text>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </>
            );
          }}
        />
      </>
    ),
  };

  useEffect(() => {
    fetchLoadingOrders({ page });
    fetchOrdersTotalCount();
  }, [page]);

  if (loading) {
    <Spinner height={100} />;
  } else if (orders.length === 0) {
    return <Empty description="Nothing here" style={{ color: ThemeColors.FontColor }} />;
  }

  return (
    <>
      <Table
        style={{ width: "100%" }}
        size="middle"
        expandable={detailsExpandable}
        dataSource={getMasterDataSource()}
        columns={getMasterColumns()}
        pagination={false}
      />
      <SetSendDate show={showSetSendDateModal} onShow={setShowSetSendDateModal} orderId={orderId} />
    </>
  );
};
