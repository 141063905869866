import React, { useEffect } from "react";
import { Button, Col, Form, Input, message, Row, Typography } from "antd";
import TextArea from "antd/lib/input/TextArea";

import { ThemeColors } from "../../consts";
import { useActions, useFocusRef, useTypedSelector } from "../../hooks";
import { getErrorMessage } from "../../utils";
import { UserService } from "../../api";

const { Title, Text } = Typography;

export const UserInfoForm = () => {
  const [form] = Form.useForm();
  const { fetchUserInfo } = useActions();

  const {
    user: {
      user: {
        id: userId,
        name: userName,
        email: userEmail,
        paypal: userPayPal,
        address: userAddress,
        phone: userPhone,
      },
    },
    util: { processing },
  } = useTypedSelector((state) => state);

  const nameRef = useFocusRef();

  const submit = async ({ name, paypal, address, phone }: any) => {
    try {
      await UserService.edit({ id: userId, name, email: userName, paypal, address, phone });
      fetchUserInfo(userId);
      message.success("Thank you! You successfully saved the user information.");
    } catch (e) {
      message.error(getErrorMessage(e));
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      name: userName,
      paypal: userPayPal,
      address: userAddress,
      phone: userPhone,
    });
  }, [userName, userPayPal, userAddress, userPhone]);

  return (
    <Form form={form} onFinish={submit} layout="horizontal">
      <Form.Item>
        <Row justify="center">
          <Col span={20}>
            <Title level={4} className="ff-title" style={{ textAlign: "center", color: ThemeColors.FontColor }}>
              Please add or correct your profile info. This will help to make ordering easier in future.
            </Title>
          </Col>
        </Row>
      </Form.Item>

      {userEmail && (
        <Form.Item label="E-mail" labelCol={{ span: 7 }} wrapperCol={{ span: 17 }}>
          <Text strong>{userEmail}</Text>
        </Form.Item>
      )}

      <Form.Item name="name" label="Recipient Name" labelCol={{ span: 7 }} wrapperCol={{ span: 17 }}>
        <Input ref={nameRef} style={{ color: ThemeColors.FontColor }} placeholder="Please enter the recipient name" />
      </Form.Item>

      <Form.Item name="paypal" label="Invoice E-mail" labelCol={{ span: 7 }} wrapperCol={{ span: 17 }}>
        <Input
          style={{ color: ThemeColors.FontColor }}
          placeholder="Please enter the invoice E-mail (PayPal / VISA/Mastercard)"
        />
      </Form.Item>

      <Form.Item name="address" label="Shipping Address" labelCol={{ span: 7 }} wrapperCol={{ span: 17 }}>
        <TextArea style={{ color: ThemeColors.FontColor }} rows={4} placeholder="Please enter the shipping address" />
      </Form.Item>

      <Form.Item name="phone" label="Phone" labelCol={{ span: 7 }} wrapperCol={{ span: 17 }}>
        <Input style={{ color: ThemeColors.FontColor }} placeholder="Please enter contact phone number" />
      </Form.Item>

      <Form.Item>
        <Row justify="end">
          <Button
            type="primary"
            htmlType="submit"
            loading={processing}
            style={{ color: ThemeColors.Main, fontWeight: 500 }}
          >
            Save
          </Button>
        </Row>
      </Form.Item>
    </Form>
  );
};
