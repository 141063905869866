import React, { useEffect } from "react";
import { Button, Form, Input, message, Modal, Row, Space } from "antd";

import { useActions, useFocusRef } from "../../hooks";
import { Keys, ThemeColors } from "../../consts";
import { getErrorMessage } from "../../utils";
import { IType } from "../../models";
import { TypeService } from "../../api";

type Props = {
  show: boolean;
  onShow: React.Dispatch<React.SetStateAction<boolean>>;
  editedType?: IType;
  setEditedType?: React.Dispatch<React.SetStateAction<IType>>;
};

export const CreateEditType = ({ show, onShow, editedType, setEditedType }: Props) => {
  const [form] = Form.useForm();
  const { fetchTypes, setSelectedType } = useActions();

  const typeNameRef = useFocusRef([show]);

  const closeDialog = () => {
    if (setEditedType) {
      setEditedType(null!);
    }
    onShow(false);
  };

  const submit = async ({ typeName }: any) => {
    try {
      if (editedType) {
        await TypeService.edit({ id: editedType.id, name: typeName });
        setSelectedType({ id: editedType.id, name: typeName });
      } else {
        await TypeService.create({ name: typeName });
      }
      fetchTypes();
    } catch (e) {
      message.error(getErrorMessage(e));
    } finally {
      closeDialog();
    }
  };

  const handleCancel = () => {
    closeDialog();
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === Keys.Enter) {
      form.submit();
    }
  };

  useEffect(() => {
    if (show) {
      editedType
        ? form.setFieldsValue({
            typeName: editedType.name,
          })
        : form.setFieldsValue({
            typeName: "",
          });
    }
  }, [show]);

  return (
    <Modal open={show} title={editedType ? "Edit Type" : "Create Type"} centered footer={false} onCancel={handleCancel}>
      <Form form={form} onFinish={submit} layout="horizontal">
        <Form.Item
          name="typeName"
          label="Type"
          labelCol={{ span: 3 }}
          wrapperCol={{ span: 21 }}
          rules={[
            {
              required: true,
              message: "Please enter the name of the product type",
            },
          ]}
        >
          <Input
            ref={typeNameRef}
            placeholder="Please enter the name of the product type"
            onKeyPress={handleKeyPress}
          />
        </Form.Item>

        <Row justify="end">
          <Space>
            <Button onClick={handleCancel} style={{ color: ThemeColors.FontColor }}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit" style={{ color: ThemeColors.Main, fontWeight: 500, minWidth: 73 }}>
              OK
            </Button>
          </Space>
        </Row>
      </Form>
    </Modal>
  );
};
