import { $authHost, $host } from "./index";
import { IItem } from "./../models/IItem";
import { AdminItemsPaginationSettings, ItemsPaginationSettings } from "../consts";

export class ItemService {
  static async create(item: Omit<IItem, "id" | "images">): Promise<IItem> {
    const { data } = await $authHost.post<IItem>("api/item", item);
    return data;
  }

  static async edit(item: Omit<IItem, "typeId" | "images">): Promise<number[]> {
    const { data } = await $authHost.put<number[]>("api/item", item);
    return data;
  }

  static async updateNumber(item: Pick<IItem, "id" | "number">): Promise<number[]> {
    const { data } = await $authHost.put<number[]>("api/item/number", item);
    return data;
  }

  static async delete(id: number): Promise<number> {
    const { data } = await $authHost.delete<number>("api/item", { data: { id } });
    return data;
  }

  static async clear(): Promise<number> {
    const { data } = await $authHost.delete<number>("api/item/clear");
    return data;
  }

  static async fetchAll(
    typeId: number,
    page: number = ItemsPaginationSettings.Page,
    limit: number = ItemsPaginationSettings.Limit
  ): Promise<IItem[]> {
    const { data } = await $host.get<IItem[]>("api/item", {
      params: { typeId, page, limit },
    });
    return data;
  }

  static async fetchOne(id: number): Promise<IItem> {
    const { data } = await $host.get<IItem>(`api/item/${id}`);
    return data;
  }

  static async count(typeId: number): Promise<number> {
    const { data } = await $host.get<number>(`api/item/count/${typeId}`);
    return data;
  }

  static async fetchAllAdmin(
    typeId: number,
    page: number = AdminItemsPaginationSettings.Page,
    limit: number = AdminItemsPaginationSettings.Limit
  ): Promise<IItem[]> {
    const { data } = await $authHost.get<IItem[]>("api/item/admin", {
      params: { typeId, page, limit },
    });
    return data;
  }

  static async countAdmin(typeId: number): Promise<number> {
    const { data } = await $authHost.get<number>(`api/item/admin/count/${typeId}`);
    return data;
  }
}
