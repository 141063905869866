import {
  ItemActionEnum,
  SetItemsAction,
  SetItemsLoadingAction,
  SetItemsPageAction,
  SetItemsTotalCountAction,
} from "./types";
import { AppDispatch } from "../..";
import { IItem } from "../../../models";
import { ItemService } from "../../../api";

export const ItemActionCreators = {
  setItems: (items: IItem[]): SetItemsAction => ({ type: ItemActionEnum.SET_ITEMS, payload: items }),
  setItemsPage: (page: number): SetItemsPageAction => ({ type: ItemActionEnum.SET_ITEMS_PAGE, payload: page }),
  setItemsTotalCount: (count: number): SetItemsTotalCountAction => ({
    type: ItemActionEnum.SET_ITEMS_TOTAL_COUNT,
    payload: count,
  }),
  setItemsLoading: (loading: boolean): SetItemsLoadingAction => ({
    type: ItemActionEnum.SET_ITEMS_LOADING,
    payload: loading,
  }),

  fetchItems: (params: { typeId: number; page?: number; limit?: number }) => async (dispatch: AppDispatch) => {
    const { typeId, page, limit } = params;
    dispatch(ItemActionCreators.setItems(await ItemService.fetchAll(typeId, page, limit)));
  },
  fetchLoadingItems: (params: { typeId: number; page?: number; limit?: number }) => async (dispatch: AppDispatch) => {
    const { typeId, page, limit } = params;
    dispatch(ItemActionCreators.setItemsLoading(true));
    try {
      dispatch(ItemActionCreators.setItems(await ItemService.fetchAll(typeId, page, limit)));
    } finally {
      dispatch(ItemActionCreators.setItemsLoading(false));
    }
  },
  fetchTotalCount: (typeId: number) => async (dispatch: AppDispatch) => {
    dispatch(ItemActionCreators.setItemsTotalCount(await ItemService.count(typeId)));
  },

  fetchAdminItems: (params: { typeId: number; page?: number; limit?: number }) => async (dispatch: AppDispatch) => {
    const { typeId, page, limit } = params;
    dispatch(ItemActionCreators.setItems(await ItemService.fetchAllAdmin(typeId, page, limit)));
  },
  fetchAdminLoadingItems:
    (params: { typeId: number; page?: number; limit?: number }) => async (dispatch: AppDispatch) => {
      const { typeId, page, limit } = params;
      dispatch(ItemActionCreators.setItemsLoading(true));
      try {
        dispatch(ItemActionCreators.setItems(await ItemService.fetchAllAdmin(typeId, page, limit)));
      } finally {
        dispatch(ItemActionCreators.setItemsLoading(false));
      }
    },
  fetchAdminTotalCount: (typeId: number) => async (dispatch: AppDispatch) => {
    dispatch(ItemActionCreators.setItemsTotalCount(await ItemService.countAdmin(typeId)));
  },
};
