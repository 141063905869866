import React from "react";
import { Card, Col, Rate, Typography } from "antd";
import { IOrderRes } from "../models/IOrderRes";
import { ThemeColors } from "../consts";

const { Text } = Typography;

type Props = {
  item: IOrderRes;
};

export const ReviewItem = ({ item }: Props) => {
  return (
    <>
      <Col xs={24} sm={24} md={12} lg={24} xl={24} xxl={24}>
        <Card
          size="small"
          hoverable
          style={{ margin: "10px  0", backgroundColor: ThemeColors.White, color: ThemeColors.FontColor }}
        >
          <div>
            <Text strong style={{ color: ThemeColors.FontColor }}>
              {item.name}
            </Text>
          </div>
          <Rate value={item.rate} disabled />
          <div>{item.review}</div>
        </Card>
      </Col>
    </>
  );
};
