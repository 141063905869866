import {
  CartActionEnum,
  SetCartItemsAction,
  SetCartLoadingAction,
  SetCartShippingAction,
  SetCartPaymentAction,
  SetCartTotalAction,
} from "./types";
import { AppDispatch } from "../..";
import { PaymentType, Shipping } from "../../../consts";
import { ICartItemsRes } from "../../../models";
import { CartService } from "../../../api";

export const CartActionCreators = {
  setCartItems: (cartItems: ICartItemsRes): SetCartItemsAction => ({
    type: CartActionEnum.SET_CART_ITEMS,
    payload: cartItems,
  }),
  setCartShipping: (shipping: number): SetCartShippingAction => ({
    type: CartActionEnum.SET_CART_SHIPPING,
    payload: shipping,
  }),
  setCartTotal: (total: number): SetCartTotalAction => ({ type: CartActionEnum.SET_CART_TOTAL, payload: total }),
  setCartPayment: (payment: PaymentType): SetCartPaymentAction => ({
    type: CartActionEnum.SET_CART_PAYMENT,
    payload: payment,
  }),
  setCartLoading: (loading: boolean): SetCartLoadingAction => ({
    type: CartActionEnum.SET_CART_LOADING,
    payload: loading,
  }),

  fetchCart: (userId: number, countryCode: number) => async (dispatch: AppDispatch) => {
    const cartItemRes = await CartService.fetchItems(userId);
    dispatch(CartActionCreators.setCartItems(cartItemRes));
    if (cartItemRes.cartItems.length > 0) {
      const shippingRes = await CartService.fetchShipping(userId, countryCode);
      dispatch(CartActionCreators.setCartShipping(!shippingRes.error ? shippingRes.cost : Shipping.Error));
      const total = await CartService.fetchTotal(userId);
      dispatch(CartActionCreators.setCartTotal(!shippingRes.error ? total + shippingRes.cost : Shipping.Error));
    }
  },

  fetchLoadingCart: (userId: number, countryCode: number) => async (dispatch: AppDispatch) => {
    dispatch(CartActionCreators.setCartLoading(true));
    try {
      const cartItemRes = await CartService.fetchItems(userId);
      dispatch(CartActionCreators.setCartItems(cartItemRes));
      if (cartItemRes.cartItems.length > 0) {
        const shippingRes = await CartService.fetchShipping(userId, countryCode);
        dispatch(CartActionCreators.setCartShipping(!shippingRes.error ? shippingRes.cost : Shipping.Error));
        const total = await CartService.fetchTotal(userId);
        dispatch(CartActionCreators.setCartTotal(!shippingRes.error ? total + shippingRes.cost : Shipping.Error));
      }
    } finally {
      dispatch(CartActionCreators.setCartLoading(false));
    }
  },

  fetchCartItems: (userId: number) => async (dispatch: AppDispatch) => {
    dispatch(CartActionCreators.setCartItems(await CartService.fetchItems(userId)));
  },

  fetchCartShipping: (userId: number, countryCode: number) => async (dispatch: AppDispatch) => {
    const shippingRes = await CartService.fetchShipping(userId, countryCode);
    dispatch(CartActionCreators.setCartShipping(!shippingRes.error ? shippingRes.cost : -1));
  },

  fetchCartTotal: (userId: number) => async (dispatch: AppDispatch) => {
    dispatch(CartActionCreators.setCartTotal(await CartService.fetchTotal(userId)));
  },
};
