import { ISubscribe } from "../../../models/ISubscribe";

export interface ISubscribeState {
  subscribers: ISubscribe[];
  page: number;
  totalCount: number;
  loading: boolean;
}

export enum SubscribeActionEnum {
  SET_SUBSCRIBERS = "SET_SUBSCRIBERS",
  SET_SUBSCRIBERS_PAGE = "SET_SUBSCRIBERS_PAGE",
  SET_SUBSCRIBERS_TOTAL_COUNT = "SET_SUBSCRIBERS_TOTAL_COUNT",
  SET_SUBSCRIBERS_LOADING = "SET_SUBSCRIBERS_LOADING",
}

export interface SetSubscribersAction {
  type: SubscribeActionEnum.SET_SUBSCRIBERS;
  payload: ISubscribe[];
}

export interface SetSubscribersPageAction {
  type: SubscribeActionEnum.SET_SUBSCRIBERS_PAGE;
  payload: number;
}

export interface SetSubscribersTotalCountAction {
  type: SubscribeActionEnum.SET_SUBSCRIBERS_TOTAL_COUNT;
  payload: number;
}

export interface SetSubscribersLoadingAction {
  type: SubscribeActionEnum.SET_SUBSCRIBERS_LOADING;
  payload: boolean;
}

export type SubscribeAction =
  | SetSubscribersAction
  | SetSubscribersPageAction
  | SetSubscribersTotalCountAction
  | SetSubscribersLoadingAction;
