import { PaymentType } from "../../../consts";
import { ICartItemRes } from "../../../models/ICartItemRes";
import { ICartItemsRes } from "../../../models/ICartItemsRes";

export interface ICartState {
  id?: number;
  items: ICartItemRes[];
  shipping: number;
  payment: PaymentType;
  total: number;
  loading: boolean;
}

export enum CartActionEnum {
  SET_CART_ITEMS = "SET_CART_ITEMS",
  SET_CART_SHIPPING = "SET_CART_SHIPPING",
  SET_CART_PAYMENT = "SET_CART_PAYMENT",
  SET_CART_TOTAL = "SET_CART_TOTAL",
  SET_CART_LOADING = "SET_CART_LOADING",
}

export interface SetCartItemsAction {
  type: CartActionEnum.SET_CART_ITEMS;
  payload: ICartItemsRes;
}

export interface SetCartShippingAction {
  type: CartActionEnum.SET_CART_SHIPPING;
  payload: number;
}

export interface SetCartPaymentAction {
  type: CartActionEnum.SET_CART_PAYMENT;
  payload: PaymentType;
}
export interface SetCartTotalAction {
  type: CartActionEnum.SET_CART_TOTAL;
  payload: number;
}

export interface SetCartLoadingAction {
  type: CartActionEnum.SET_CART_LOADING;
  payload: boolean;
}

export type CartAction =
  | SetCartItemsAction
  | SetCartShippingAction
  | SetCartPaymentAction
  | SetCartTotalAction
  | SetCartLoadingAction;
