import { IOrderRes } from "../../../models/IOrderRes";

export interface IOrderState {
  orders: IOrderRes[];
  page: number;
  totalCount: number;
  loading: boolean;
}

export enum OrderActionEnum {
  SET_ORDERS = "SET_ORDERS",
  SET_ORDERS_PAGE = "SET_ORDERS_PAGE",
  SET_ORDERS_TOTAL_COUNT = "SET_ORDERS_TOTAL_COUNT",
  SET_ORDERS_LOADING = "SET_ORDERS_LOADING",
}

export interface SetOrdersAction {
  type: OrderActionEnum.SET_ORDERS;
  payload: IOrderRes[];
}

export interface SetOrdersPageAction {
  type: OrderActionEnum.SET_ORDERS_PAGE;
  payload: number;
}

export interface SetOrdersLoadingAction {
  type: OrderActionEnum.SET_ORDERS_LOADING;
  payload: boolean;
}

export interface SetOrdersTotalCountAction {
  type: OrderActionEnum.SET_ORDERS_TOTAL_COUNT;
  payload: number;
}

export type OrderAction = SetOrdersAction | SetOrdersPageAction | SetOrdersTotalCountAction | SetOrdersLoadingAction;
