import s from "./HeaderCart.module.css";

import React, { useEffect } from "react";
import { createFromIconfontCN } from "@ant-design/icons";
import { Space } from "antd";
import { useActions, useTypedSelector } from "../../../../../hooks";
import { useNavigate } from "react-router-dom";
import { RouteNames } from "../../../../../routes";

const CartOutlined = createFromIconfontCN({
  scriptUrl: ["//at.alicdn.com/t/font_1788044_0dwu4guekcwr.js"],
});

export const HeaderCart = () => {
  const navigate = useNavigate();
  const { fetchCartItems } = useActions();

  const {
    user: {
      user: { id: userId },
    },
    cart: { items: cartItems },
  } = useTypedSelector((state) => state);

  useEffect(() => {
    if (userId) {
      fetchCartItems(userId);
    }
  }, [userId]);

  return (
    <div className={s.cart} onClick={() => navigate(RouteNames.CART)}>
      <Space>
        <CartOutlined className={s.cartImage} type="icon-shoppingcart" />
        <span className={s.number}>{cartItems.length}</span>
      </Space>
    </div>
  );
};
